import axios from "axios";
import throttle from "lodash-es/throttle";
import { fetchAuthToken } from "app/Auth";
import Constants from "common/constant";
import { client } from "common/apiClient";

export const uploadImageFile = async ({
  fileBlob,
  filename,
  metadata,
  contentType,
}: {
  fileBlob: Blob | File;
  filename: string;
  metadata?: any;
  contentType?: string;
}) => {
  const token = await fetchAuthToken();
  const client = axios.create({ baseURL: Constants.apiHost });
  client.defaults.headers.common["Authorization"] = token;
  const formData = new FormData();
  formData.append("file", fileBlob);
  formData.append("filename", filename);
  formData.append("metadata", JSON.stringify(metadata));
  formData.append("contentType", contentType);
  //is_sync:false statusの200はサーバは完全にファイルアップロード処理が完了したわけではない
  //is_sync:true statusの200はサーバは完全にファイルアップロード処理が完了した
  return client.post("/v1/upload/file/image?is_sync=true", formData);
};

export const updateUserProfile = throttle(
  async ({
    uid,
    userInfo,
  }: {
    uid: string;
    userInfo: { icon?: string; name?: string };
  }) => {
    const token = await fetchAuthToken();
    return client({ token: token }).put(`/v1/user/profile/${uid}`, userInfo);
  },
  1000
);

export const deleteParentsUser = async ({
  uid,
  options,
  detail,
}: {
  uid: string;
  options: string[];
  detail: string;
}) => {
  const token = await fetchAuthToken();
  return client({ token: token }).delete(`/v1/user/parents/${uid}`, {
    data: {
      options,
      detail,
    },
  });
};
