import { useDispatch } from "react-redux";
import I18n from "i18n-js";
import { Box, Button, Stack, Typography } from "@mui/material";
import { actions } from "app/appConfigSlice";
import { GameCase, DialogType } from "app/types";
import Constants from "common/constant";
import { useScale } from "common/utils";
import { IconRunPreview, IconPlayerPlay } from "common/icons";

export const CreatorGameCaseManager = {
  [GameCase.GRAY]: "small_gray_case.png",
  [GameCase.SOFT_YELLOW]: "small_soft_yellow_case.png",
  [GameCase.GREEN]: "small_green_case.png",
  [GameCase.CYAN]: "small_cyan_case.png",
  [GameCase.BLUE]: "small_blue_case.png",
  [GameCase.MILK]: "small_milk_case.png",
  [GameCase.PINK]: "small_pink_case.png",
  [GameCase.ORANGE]: "small_orange_case.png",
  [GameCase.SOFT_PINK]: "small_soft_pink_case.png",
};

export const ProjectItemComponent = (props: { messageContent: any }) => {
  const { scale } = useScale();
  const dispatch = useDispatch();
  const { messageContent } = props;
  const GameCaseComponent = messageContent.game_case
    ? CreatorGameCaseManager[messageContent.game_case]
    : "small_gray_case.png";

  const handleRunProject = () => {
    dispatch(
      actions.updateDialog({
        type: DialogType.PROJECT_PREVIEW,
        value: true,
        args: {
          projectId: messageContent.project_id,
          gameCase: messageContent.game_case,
        },
      })
    );
  };

  return (
    <Stack
      width="60%"
      direction="column"
      alignItems="center"
      justifyContent="center"
      className="mr-1"
    >
      <Stack my={1} width="100%" direction="row" justifyContent="flex-start">
        <Stack className="p-3 rounded-md bg-gray/20">
          <Typography
            className="break-words"
            component="p"
            variant="body1"
            color="text.primary"
          >
            {I18n.t("MSG_CHAT_MESSAGE_PUBLISHED_PROJECT_MESSAGE", {
              name: messageContent.name,
            })}
          </Typography>
        </Stack>
      </Stack>

      {/* 4 : 3 */}
      <div className="w-[100%] pt-[75%] relative">
        <div className="w-full h-full absolute top-0 left-0">
          {/* ケースのキャプチャエリアのオリジナルサイズ: 562px * 414px */}
          <Stack
            width="100%"
            height="100%"
            direction="column"
            alignItems="center"
            justifyContent="center"
            position="absolute"
            zIndex={10}
          >
            <div className="w-[85%] h-[86%] relative box-border border-solid border-gray/100 border-[6px] rounded-[16px] overflow-hidden">
              {messageContent.capture ? (
                <img
                  alt="プロジェクトキャプチャ画像"
                  src={messageContent.capture}
                  className="w-ull h-full object-contain"
                />
              ) : (
                <div className="w-full h-full bg-white" />
              )}

              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                position="absolute"
                width="100%"
                height={{ xs: 16, sm: 24, md: 48 }}
                bottom={0}
                sx={{
                  borderWidth: "1px 0 0 0",
                  boxShadow: "0px -1px 4px rgba(63, 74, 97, 0.15)",
                }}
              >
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  position="absolute"
                  bottom={0}
                  sx={{
                    transformOrigin: "bottom",
                    transform: `translateZ(0) scale(${
                      scale < 0.5 ? scale * 1.5 : scale
                    })`,
                  }}
                >
                  <Button
                    sx={{
                      width: 120,
                      bgcolor: "transparent",
                      padding: 0,
                      color: "transparent",
                      border: "none",
                    }}
                    onClick={handleRunProject}
                  >
                    <Stack
                      width="100%"
                      direction="column"
                      alignItems="center"
                      position="absolute"
                      top="-12px"
                      left={0}
                    >
                      <Stack
                        width="124px"
                        height="95px"
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <IconRunPreview />
                      </Stack>
                    </Stack>

                    <Stack
                      zIndex={50}
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Stack
                        width="40px"
                        height="40px"
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <IconPlayerPlay color="white" width={2} />
                      </Stack>
                      <Typography color="primary.contrastText">
                        {I18n.t("MSG_CHAT_MESSAGE_RUN_APP_BTN")}
                      </Typography>
                    </Stack>
                  </Button>
                </Stack>
              </Stack>
            </div>
          </Stack>

          {/* ケースのオリジナルサイズ: 664px * 498px */}
          <Box position="absolute" top={0} left={0} width="100%" height="100%">
            <img
              alt="game case"
              src={`${Constants.assetHost}/assets/images/${GameCaseComponent}`}
              className="w-full h-full"
            />
          </Box>
        </div>
      </div>
    </Stack>
  );
};
