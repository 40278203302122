import { useState, FormEvent, PointerEvent } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Grid,
  Stack,
  Button,
  Backdrop,
  Container,
  IconButton,
  CircularProgress,
} from "@mui/material";
import I18n from "i18n-js";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { DialogType } from "app/types";
import { actions } from "app/appConfigSlice";

export const MailSignIn = ({ navigation }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [required, setRequired] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const data = new FormData(event.currentTarget);
      const email = data.get("email").toString();
      const password = data.get("password").toString();
      if (email && password) {
        setLoading(true);
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password).catch((error) => {
          setLoading(false);
          dispatch(
            actions.updateDialog({
              type: DialogType.SNACKBAR_ALERT,
              value: true,
              args: {
                type: "error",
                message: I18n.t("MSG_APP_ERROR_SIGN_IN_FAILED"),
              },
            })
          );
        });
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      dispatch(
        actions.updateDialog({
          type: DialogType.SNACKBAR_ALERT,
          value: true,
          args: {
            type: "error",
            message: I18n.t("MSG_APP_ERROR_SIGN_IN_FAILED"),
          },
        })
      );
    }
  };

  const handleGotoResetPassword = (e: PointerEvent) => {
    e.preventDefault();
    e.stopPropagation();

    navigation.navigate("MailResetPassword");
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Stack direction="column" alignItems="center" justifyContent="center">
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }} gap={3}>
          <div className="flex-col-el">
            <input
              id="email"
              name="email"
              type="email"
              required={required}
              className="peer input-form text-body text-textcolor/black text-font-size-body2-narrow"
              placeholder={I18n.t("MSG_SIGN_USER_MAIL")}
              autoFocus={false}
            />
            <label className="text-body text-left text-[14px] h-5 text-danger hidden peer-invalid:block">
              {I18n.t("MSG_APP_ERROR_EMAIL_NOT_VALID")}
            </label>
          </div>

          <div className="flex-row-el relative items-center my-6">
            <input
              id="password"
              name="password"
              required={required}
              type={showPassword ? "text" : "password"}
              className="peer input-form text-body text-textcolor/black text-font-size-body2-narrow"
              placeholder={I18n.t("MSG_SIGN_USER_PASSWORD")}
              autoFocus={false}
              onChange={() => setRequired(false)}
            />
            <div className="flex-col-el absolute right-0 pr-3">
              <IconButton onClick={handleClickShowPassword}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </div>
          </div>

          <Stack
            mb={2}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              sx={{ height: 40, width: 302 }}
            >
              {I18n.t("MSG_SIGN_IN_BTN")}
            </Button>
          </Stack>

          <Grid container justifyContent="center">
            <Grid item>
              <Button color="primary" onPointerDown={handleGotoResetPassword}>
                {I18n.t("MSG_SIGN_IN_FORGOT_PASSWORD")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Container>
  );
};
