import axios from "axios";
import Constants from "common/constant";

export const client = (args: { token: string }) => {
  const { token } = args;
  const client = axios.create({ baseURL: Constants.apiHost });
  client.defaults.headers.common["Authorization"] = token;
  client.defaults.headers.common["Content-Type"] = "application/json";
  return client;
};
