import { useLayoutEffect } from "react";
import { Box, Container, Typography } from "@mui/material";

export const NotificationDetail = ({ route, navigation }) => {
  const { date, title, content } = route.params.notification;

  useLayoutEffect(() => {
    navigation.setOptions({
      title: title,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route, navigation]);

  return (
    <Container component="main" maxWidth="xs" sx={{ overflow: "auto" }}>
      <Box
        sx={{
          marginTop: 5,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography component="h1" variant="h5" color="text.primary">
          {title}
        </Typography>

        <Box
          sx={{
            marginTop: 1,
            marginBottom: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="p" variant="subtitle1" color="text.primary">
            {date}
          </Typography>
        </Box>

        {content.split("\n").map((p: string, index: number) => (
          <Typography
            key={index}
            component="p"
            variant="body1"
            color="text.primary"
            sx={{ marginBottom: 1, textAlign: "left" }}
          >
            {p}
          </Typography>
        ))}
      </Box>
    </Container>
  );
};
