import ReactDOM from "react-dom";
import "styles/index.scss";
import dayjs from "dayjs";
import i18n from "i18n-js";
import App from "app/App";
import { I18nTranslations } from "common/i18n";
import { insideInstalledApp } from "common/utils";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

i18n.locale = "ja_kanji";
i18n.fallbacks = true;
i18n.translations = I18nTranslations;

ReactDOM.render(<App />, document.getElementById("root"));

declare global {
  interface Window {
    deferredPrompt?: any;
    ReactNativeWebView?: any;
    parentsAppHostLanguage?: string;
    parentsAppPlatform?: string;
    parentsAppVersion?: string;
    parentsBuildVersion?: string;
  }
}

if (insideInstalledApp()) {
  window.resizeBy(540 - window.innerWidth, 960 - window.innerHeight);
}
