import dayjs from "dayjs";
import { ListItem, Typography, ListItemAvatar } from "@mui/material";
import { UserAvatar } from "common/elements";
import { ChatRoomMessage } from "features/home/types";
import { MessageItem } from "./MessageItem";

export const ChatMessageItem = (props: {
  isFromUser: boolean;
  name: string;
  icon: string;
  message: ChatRoomMessage;
}) => {
  const { isFromUser, name, icon, message } = props;
  return isFromUser ? (
    <ListItem
      disablePadding
      className="w-full flex flex-row items-start justify-end my-4"
    >
      <div className="flex flex-col justify-end items-end w-full">
        <div className="flex flex-row-reverse items-end">
          <Typography
            sx={{ display: "inline" }}
            component="span"
            variant="subtitle2"
            color="text.primary"
          >
            {name}
          </Typography>
        </div>

        <div className="flex flex-row w-full items-end justify-end">
          <Typography
            sx={{ display: "inline" }}
            component="span"
            variant="body2"
            className="text-gray/100"
          >
            {dayjs(message.created_at, "YYYY-MM-DD HH:mm:ssZ").format("HH:mm")}
          </Typography>

          <MessageItem message={message} isFromUser={true} />
        </div>
      </div>

      <ListItemAvatar className="mx-4">
        <UserAvatar src={icon} />
      </ListItemAvatar>
    </ListItem>
  ) : (
    <ListItem disablePadding className="flex flex-row items-start w-full my-4">
      <ListItemAvatar className="pt-[10px] mr-4">
        <UserAvatar src={icon} />
      </ListItemAvatar>

      <div className="flex flex-col items-start w-full">
        <div className="flex flex-row-reverse items-end">
          <Typography
            sx={{ display: "inline" }}
            component="span"
            variant="subtitle2"
            color="text.primary"
          >
            {name}
          </Typography>
        </div>

        <div className="flex flex-row w-full items-end justify-start">
          <MessageItem message={message} isFromUser={false} />
          <Typography
            sx={{ display: "inline" }}
            component="span"
            variant="body2"
            className="text-gray/100"
          >
            {dayjs(message.created_at, "YYYY-MM-DD HH:mm:ssZ").format("HH:mm")}
          </Typography>
        </div>
      </div>
    </ListItem>
  );
};
