import { useState, FormEvent } from "react";
import {
  getAuth,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "firebase/auth";
import {
  Box,
  Stack,
  Button,
  Backdrop,
  Container,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import I18n from "i18n-js";
import ErrorIcon from "@mui/icons-material/Error";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

export const ChangePassword = ({ navigation }) => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [required, setRequired] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [currentPasswordError, setCurrentPasswordError] = useState(false);
  const [newPasswordConfirmError, setNewPasswordConfirmError] = useState(false);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const currentUser = getAuth().currentUser;
      const data = new FormData(event.currentTarget);
      const currentPassword = data.get("current-password").toString();
      const newPassword = data.get("new-password").toString();
      const newPasswordConfirm = data.get("new-password-confirm").toString();
      if (currentPassword && newPassword && newPasswordConfirm) {
        if (newPassword === newPasswordConfirm) {
          const credential = EmailAuthProvider.credential(
            currentUser.email,
            currentPassword
          );
          setLoading(true);
          await reauthenticateWithCredential(currentUser, credential);
          await updatePassword(currentUser, newPassword);
          setLoading(false);
          setSuccess(true);
        } else {
          setNewPasswordConfirmError(true);
        }
      } else {
        setNewPasswordConfirmError(true);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      if (error?.code === "auth/wrong-password") {
        setCurrentPasswordError(true);
      }
    }
  };

  const handleSuccessClose = () => {
    setSuccess(false);
    navigation.goBack();
  };

  const handleErrorClose = () => {
    setError(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={success}
      >
        <Stack
          height="100%"
          direction="column"
          justifyContent="center"
          alignItems="center"
          onClick={handleSuccessClose}
        >
          <Stack
            p={4}
            boxShadow={24}
            width={{ xs: 300 }}
            bgcolor="background.paper"
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Stack direction="row" alignItems="center" justifyContent="center">
              <CheckCircleRoundedIcon color="success" />

              <Typography ml={1} variant="body1" component="div" align="center">
                {I18n.t("MSG_SIGN_IN_CHANGE_PASSWORD_SUCCESS_TITLE")}
              </Typography>
            </Stack>

            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              align="center"
            >
              {I18n.t("MSG_SIGN_IN_CHANGE_PASSWORD_SUCCESS_CONTENT")}
            </Typography>
          </Stack>
        </Stack>
      </Backdrop>

      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={error}
        onClick={handleErrorClose}
      >
        <Stack
          p={4}
          boxShadow={24}
          width={{ xs: 300 }}
          bgcolor="background.paper"
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Stack direction="row" alignItems="center" justifyContent="center">
            <ErrorIcon color="error" />

            <Typography ml={1} variant="body1" component="div" align="center">
              {I18n.t("MSG_APP_ERROR_TITLE")}
            </Typography>
          </Stack>

          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            align="center"
          >
            {I18n.t("MSG_APP_ERROR_SIGN_IN_FAILED")}
          </Typography>
        </Stack>
      </Backdrop>

      <Stack
        mt={8}
        direction="column"
        justifyContent="center"
        alignItems="center"
        onClick={handleErrorClose}
      >
        <Box mt={1} width="100%" component="form" onSubmit={handleSubmit}>
          <div className="flex-col-el relative">
            <input
              id="current-password"
              name="current-password"
              required={required}
              type={showPassword ? "text" : "password"}
              className={`peer input-form text-body text-textcolor/black text-font-size-body2-narrow ${
                currentPasswordError && "!border-danger"
              }`}
              placeholder={I18n.t(
                "MSG_SIGN_CHANGE_PASSWORD_LABEL_CURRENT_PASSWORD"
              )}
              autoFocus={false}
              onChange={() => setCurrentPasswordError(false)}
            />
            <label
              className={`text-body text-left text-[14px] h-5 text-danger hidden ${
                currentPasswordError && "!block"
              }`}
            >
              {I18n.t("MSG_SIGN_IN_CHANGE_PASSWORD_CURRENT_PASSWORD_WRONG")}
            </label>
            <div className="flex-col-el absolute right-0 pt-3 pr-3">
              <IconButton onClick={handleClickShowPassword}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </div>
          </div>

          <div className="flex-col-el relative my-5">
            <input
              id="new-password"
              name="new-password"
              required={required}
              type={showPassword ? "text" : "password"}
              className="peer input-form text-body text-textcolor/black text-font-size-body2-narrow"
              placeholder={I18n.t(
                "MSG_SIGN_CHANGE_PASSWORD_LABEL_NEW_PASSWORD"
              )}
              autoFocus={false}
            />
            <label className="text-body text-left text-[14px] h-5 text-danger hidden peer-invalid:block">
              {I18n.t("MSG_APP_ERROR_PASSWORD_TOO_SHORT")}
            </label>
            <div className="flex-col-el absolute right-0 pt-3 pr-3">
              <IconButton onClick={handleClickShowPassword}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </div>
          </div>

          <div className="flex-col-el relative">
            <input
              id="new-password-confirm"
              name="new-password-confirm"
              required={required}
              type={showPassword ? "text" : "password"}
              className={`peer input-form text-body text-textcolor/black text-font-size-body2-narrow ${
                newPasswordConfirmError && "!border-danger"
              }`}
              placeholder={I18n.t(
                "MSG_SIGN_CHANGE_PASSWORD_LABEL_NEW_PASSWORD_CONFIRM"
              )}
              autoFocus={false}
              onChange={() => setNewPasswordConfirmError(false)}
            />
            <label
              className={`text-body text-left text-[14px] h-5 text-danger hidden ${
                newPasswordConfirmError && "!block"
              }`}
            >
              {I18n.t("MSG_APP_ERROR_PASSWORD_CONFIRM_NOT_VALID")}
            </label>
            <div className="flex-col-el absolute right-0 pt-3 pr-3">
              <IconButton onClick={handleClickShowPassword}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </div>
          </div>

          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => setRequired(true)}
          >
            {I18n.t("MSG_SIGN_IN_CHANGE_PASSWORD_SEND_BTN")}
          </Button>
        </Box>
      </Stack>
    </Container>
  );
};
