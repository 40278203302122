/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  query,
  limit,
  orderBy,
  collection,
  onSnapshot,
  getFirestore,
} from "firebase/firestore";
import { Unsubscribe } from "firebase/auth";
import { AppDispatch } from "app/store";
import { ChatRoomMessage } from "features/home/types";
import { actions, selectAllChatRooms } from "features/home/slice";

export const useSubscribe = () => {
  const dispatch = useDispatch<AppDispatch>();
  const chatRooms = useSelector(selectAllChatRooms);
  const [chatMessageSubscribes, setChatMessageSubscribes] = useState<
    { chatRoomId: string; unsubscribe: Unsubscribe }[]
  >([]);

  const subscribeUserChatRoomMessage = (chatRoomId: string, uid: string) => {
    const now = dayjs().format("YYYY-MM-DD HH:mm:ssZ");
    dispatch(
      actions.updateChatRoomMessageLastSyncAt({
        chatRoomId: chatRoomId,
        uid,
        lastMessageSyncAt: now,
      })
    );

    const db = getFirestore();
    const initialMessages = [];
    const messages = query(
      collection(db, "rooms", chatRoomId, "messages"),
      orderBy("created_at", "desc"),
      limit(20)
    );

    var initial = true;
    const unsubscribe = onSnapshot(messages, (snapshot) => {
      if (initial) {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            initialMessages.push(change.doc.data());
            if (change.newIndex === snapshot.size - 1) {
              dispatch(
                actions.bulkInsertChatRoomMessage({
                  uid,
                  messages: initialMessages,
                })
              );
            }
          }
        });
        initial = false;
      } else {
        snapshot.docChanges().forEach((change) => {
          switch (change.type) {
            case "added":
              dispatch(
                actions.insertChatRoomMessage({
                  uid,
                  message: change.doc.data() as ChatRoomMessage,
                })
              );
              break;
          }
        });
      }
    });

    return { chatRoomId, unsubscribe };
  };

  useEffect(() => {
    const deletedUserChatSubscribes = chatMessageSubscribes.filter(
      (chatMessageSubscribe) =>
        !chatRooms
          .map((chatRoom) => chatRoom.id)
          .includes(chatMessageSubscribe.chatRoomId)
    );

    deletedUserChatSubscribes.forEach((deletedUserChatSubscribe) =>
      deletedUserChatSubscribe.unsubscribe()
    );

    const newUserChatSubscribes = chatRooms
      .filter(
        (chatRoom) =>
          !chatMessageSubscribes
            .map((chatMessageSubscribe) => chatMessageSubscribe.chatRoomId)
            .includes(chatRoom.id)
      )
      .map((chatRoom) =>
        subscribeUserChatRoomMessage(chatRoom.id, chatRoom.uid)
      );

    const currentUserChatSubscribes = chatMessageSubscribes.filter(
      (chatMessageSubscribe) =>
        chatRooms
          .map((chatRoom) => chatRoom.id)
          .includes(chatMessageSubscribe.chatRoomId)
    );

    setChatMessageSubscribes(
      currentUserChatSubscribes.concat(newUserChatSubscribes)
    );
  }, [chatRooms]);

  useEffect(() => {
    return () => {
      chatMessageSubscribes.map((chatMessageSubscribe) =>
        chatMessageSubscribe.unsubscribe()
      );
    };
  }, []);
};
