import I18n from "i18n-js";
import { Stack, Typography } from "@mui/material";
import { SSO } from "./SSO";
import { MailSignIn } from "./MailSignIn";

export const SignIn = ({ navigation }) => {
  return (
    <Stack
      width="100%"
      height="100%"
      direction="column"
      alignItems="center"
      py={5}
      overflow="auto"
    >
      <Stack
        paddingX={2}
        width="100%"
        direction="column"
        alignItems="center"
        justifyContent="center"
        gap={3}
      >
        <Stack
          maxWidth="300px"
          direction="column"
          alignItems="center"
          justifyContent="center"
          gap={3}
        >
          <MailSignIn navigation={navigation} />

          <Typography variant="subtitle1">
            {I18n.t("MSG_SIGN_OTHER_ACCOUNT")}
          </Typography>

          <Stack
            maxWidth="300px"
            direction="column"
            alignItems="center"
            justifyContent="center"
            gap={3}
          >
            <SSO />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
