import { useState, useEffect, useContext } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { Provider, useDispatch, useSelector } from "react-redux";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { DefaultTheme, NavigationContainer } from "@react-navigation/native";
import {
  User,
  getAuth,
  onAuthStateChanged,
  connectAuthEmulator,
} from "firebase/auth";
import I18n from "i18n-js";
import {
  getFirestore,
  connectFirestoreEmulator,
  enableIndexedDbPersistence,
} from "firebase/firestore";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import NotificationsIcon from "@mui/icons-material/Notifications";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { AuthContext } from "app/Auth";
import { DialogPage } from "app/DialogPage";
import { store, RootState, AppDispatch } from "app/store";
import Constants from "common/constant";
import { Top } from "features/top";
import { Home } from "features/home";
import { Splash } from "features/splash";
import { Profile } from "features/profile";
import { Onboarding } from "features/onboarding";
import { Notification } from "features/notification";
import { About, UserUnsubscribe } from "features/about";
import { NotificationDetail } from "features/notification/NotificationDetail";
import { Sign, SignIn, ChangePassword, MailResetPassword } from "features/sign";
import { getEnv } from "./appConfigSlice";
import { useInitialApp } from "./useInitApp";
import { getUser, getUserConfig } from "./api";

const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: "#FEFDFE",
  },
};

declare global {
  namespace JSX {
    interface IntrinsicElements {
      xml: unknown;
      sep: unknown;
      category: unknown;
      block: unknown;
      value: unknown;
      field: unknown;
      shadow: unknown;
      variables: unknown;
      variable: unknown;
      mutation: unknown;
    }
  }
}

const Tab = createBottomTabNavigator();
const MainScreen = () => {
  const notificationNotMarked = useSelector(
    (state: RootState) => state.appConfig.notificationNotMarked
  );

  return (
    <Tab.Navigator
      screenOptions={{
        headerStyle: {
          height: 80,
          backgroundColor: "#F8F1E8",
          borderStyle: "solid",
          borderBottomColor: "#B7714D",
          borderBottomWidth: 4,
        },
        tabBarStyle: {
          height: 60,
          backgroundColor: "#F8F1E8",
          borderStyle: "solid",
          borderTopColor: "#B7714D",
          borderTopWidth: 4,
        },
      }}
    >
      <Tab.Screen
        name={I18n.t("MSG_SCREEN_NAME_HOME")}
        options={{
          tabBarIcon: ({ focused, color, size }) => (
            <QuestionAnswerIcon
              fontSize="large"
              color={focused ? "primary" : "disabled"}
            />
          ),
          tabBarBadgeStyle: {
            minWidth: 10,
            height: 10,
            fontSize: 10,
            lineHeight: 10,
          },
          tabBarActiveTintColor: "#2BDC98",
        }}
        component={Home}
      />
      <Tab.Screen
        name={I18n.t("MSG_SCREEN_NAME_NOTIFICATION")}
        options={{
          tabBarIcon: ({ focused, color, size }) => (
            <NotificationsIcon
              fontSize="large"
              color={focused ? "primary" : "disabled"}
            />
          ),
          tabBarBadge: notificationNotMarked ? "" : null,
          tabBarBadgeStyle: {
            minWidth: 10,
            height: 10,
            fontSize: 10,
            lineHeight: 10,
          },
          tabBarActiveTintColor: "#2BDC98",
        }}
        component={Notification}
      />
    </Tab.Navigator>
  );
};

const StackNavigator = createNativeStackNavigator();
const StackApp = (props: { authing: boolean }) => {
  const { authing } = props;
  const auth = useContext(AuthContext);
  const loading = useSelector((state: RootState) => state.appConfig.loading);

  if (loading || authing) {
    return (
      <StackNavigator.Navigator>
        <StackNavigator.Screen
          name="Splash"
          component={Splash}
          options={{
            headerShown: false,
            title: "みまもりアプリ",
          }}
        />
      </StackNavigator.Navigator>
    );
  }

  return (
    <StackNavigator.Navigator
      screenOptions={{
        headerTitleAlign: "center",
        headerStyle: {
          backgroundColor: "#F8F1E8",
          //@ts-ignore
          borderStyle: "solid",
          borderBottomColor: "#B7714D",
          borderBottomWidth: 4,
        },
      }}
    >
      {auth.user ? (
        <>
          <StackNavigator.Screen
            name="Top"
            component={Top}
            options={{
              headerShown: false,
              title: "みまもりアプリ",
            }}
          />

          <StackNavigator.Screen
            name="Onboarding"
            component={Onboarding}
            options={{
              headerShown: false,
              title: "みまもりアプリ",
            }}
          />

          <StackNavigator.Screen
            name="Main"
            component={MainScreen}
            options={{
              headerShown: false,
              title: "みまもりアプリ",
            }}
          />

          <StackNavigator.Screen
            name={I18n.t("MSG_MENU_PROFILE")}
            component={Profile}
          />

          <StackNavigator.Screen
            name="ChangePassword"
            component={ChangePassword}
            options={{
              title: "パスワード変更",
            }}
          />

          <StackNavigator.Screen
            name="About"
            component={About}
            options={{
              title: "アプリについて",
            }}
          />

          <StackNavigator.Screen
            name="Unsubscribe"
            component={UserUnsubscribe}
            options={{
              title: "アカウント削除",
            }}
          />

          <StackNavigator.Screen
            name="NotificationDetail"
            component={NotificationDetail}
          />
        </>
      ) : (
        <>
          <StackNavigator.Screen
            name="Sign"
            component={Sign}
            options={{
              headerShown: false,
              title: "みまもりアプリ",
            }}
          />
          <StackNavigator.Screen
            name="SignIn"
            component={SignIn}
            options={{
              headerTitle: "アカウントログイン",
              title: "アカウントログイン",
            }}
          />
          <StackNavigator.Screen
            name="MailResetPassword"
            component={MailResetPassword}
            options={{
              headerTitle: "パスワードリセット",
              title: "パスワードリセット",
            }}
          />
        </>
      )}
    </StackNavigator.Navigator>
  );
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#2BDC98",
      contrastText: "#FEFDFE",
    },
    text: {
      primary: "#3F4A61",
    },
  },
  typography: {
    fontFamily: [
      '"M PLUS Rounded 1c"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

const AuthProvider = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [authing, setAuthing] = useState(true);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const auth = getAuth();

    if (process.env.NODE_ENV === "development") {
      const db = getFirestore();
      connectFirestoreEmulator(
        db,
        Constants.firebaseEmulatorsHost,
        Number(Constants.firebaseEmulatorsFirestorePort)
      );
      connectAuthEmulator(
        auth,
        `http://${Constants.firebaseEmulatorsHost}:${Constants.firebaseEmulatorsAuthPort}`,
        {
          disableWarnings: true,
        }
      );
    } else {
      const db = getFirestore();
      enableIndexedDbPersistence(db);
    }

    const unsubscribe = onAuthStateChanged(auth, async (authUser: User) => {
      if (authUser) {
        dispatch(getUser({ uid: authUser.uid, env: getEnv() }))
          .then(unwrapResult)
          .then(async (result) => {
            if (result.users.length > 0) {
              dispatch(getUserConfig({ uid: authUser.uid }))
                .then(unwrapResult)
                .then(() => {
                  setAuthing(false);
                  setUser(authUser);
                })
                .catch((error) => {
                  console.error(error);
                });
            } else {
              await auth.signOut();
            }
          })
          .catch((error) => {
            console.error(error);
            console.error(JSON.stringify(error));
          });
      } else {
        setAuthing(false);
        setUser(authUser);
      }
    });
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={{ user }}>
      <DialogPage />
      <StackApp authing={authing} />
    </AuthContext.Provider>
  );
};

const App = () => {
  useInitialApp();
  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <Provider store={store}>
          <NavigationContainer theme={MyTheme}>
            <AuthProvider />
          </NavigationContainer>
        </Provider>
      </StyledEngineProvider>
    </ThemeProvider>
  );
};

export default App;
