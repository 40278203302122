import { useState, useContext } from "react";
import {
  Box,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import I18n from "i18n-js";
import { getAuth, signOut } from "firebase/auth";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import LockResetIcon from "@mui/icons-material/LockReset";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { AuthContext } from "app/Auth";

export const MenuButton = ({ navigation }) => {
  const firebaseAuth = getAuth();
  const auth = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNavi = (scree: string) => () => {
    setAnchorEl(null);
    navigation.navigate(scree);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    signOut(firebaseAuth);
  };

  return (
    <Box>
      <IconButton onClick={handleOpenMenu}>
        <SettingsIcon fontSize="large" />
      </IconButton>
      <Menu
        id="setting-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "setting-button",
        }}
      >
        <MenuItem onClick={handleNavi(I18n.t("MSG_MENU_PROFILE"))}>
          <ListItemIcon sx={{ marginRight: 2 }}>
            <AccountCircleIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText primary={I18n.t("MSG_MENU_PROFILE")} />
        </MenuItem>
        <MenuItem onClick={handleNavi("Onboarding")}>
          <ListItemIcon sx={{ marginRight: 2 }}>
            <HelpOutlineIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText primary={I18n.t("MSG_MENU_ONBOARDING")} />
        </MenuItem>
        {auth.user.providerData[0].providerId !== "google.com" && (
          <MenuItem onClick={handleNavi("ChangePassword")}>
            <ListItemIcon sx={{ marginRight: 2 }}>
              <LockResetIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText primary={I18n.t("MSG_MENU_CHANGE_PASSWORD")} />
          </MenuItem>
        )}
        <MenuItem onClick={handleNavi("About")}>
          <ListItemIcon sx={{ marginRight: 2 }}>
            <InfoOutlinedIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText primary={I18n.t("MSG_MENU_APP_ABOUT")} />
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon sx={{ marginRight: 2 }}>
            <LogoutIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText primary={I18n.t("MSG_MENU_LOGOUT")} />
        </MenuItem>
      </Menu>
    </Box>
  );
};
