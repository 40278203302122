import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { RootState } from "app/store";
import {
  getUserChatRooms,
  getChatRoomMessages,
  markChatRoomMessage,
} from "features/home/api";
import {
  ChatRoom,
  ChatRoomMessage,
  HomeInitialState,
  ChatRoomMessageSync,
} from "features/home/types";

export const chatRoomAdapter = createEntityAdapter<ChatRoom>({
  sortComparer: (a, b) => {
    if (a.created_at > b.created_at) {
      return 1;
    } else {
      return -1;
    }
  },
});
export const { selectAll: selectAllChatRooms } = chatRoomAdapter.getSelectors(
  (state: RootState) => state.home.chatRooms
);

export const chatRoomMessageAdapter = createEntityAdapter<ChatRoomMessage>({
  sortComparer: (a, b) => {
    if (a.created_at > b.created_at) {
      return 1;
    } else {
      return -1;
    }
  },
});
export const {
  selectAll: selectAllChatRoomMessages,
} = chatRoomMessageAdapter.getSelectors(
  (state: RootState) => state.home.chatRoomMessages
);

export const chatRoomMessageSyncAdapter = createEntityAdapter<ChatRoomMessageSync>();
export const {
  selectAll: selectAllChatRoomMessageSync,
  selectById: selectChatRoomMessageSyncById,
} = chatRoomMessageSyncAdapter.getSelectors(
  (state: RootState) => state.home.chatRoomMessageSync
);

const initialState: HomeInitialState = {
  isRefreshed: false,
  selectedChatRoomId: null,
  selectedUserUid: null,
  chatRooms: chatRoomAdapter.getInitialState(),
  chatRoomMessages: chatRoomMessageAdapter.getInitialState(),
  chatRoomMessageSync: chatRoomMessageSyncAdapter.getInitialState(),
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    updateIsRefreshed(
      state: HomeInitialState,
      action: PayloadAction<{ refreshed: boolean }>
    ) {
      state.isRefreshed = action.payload.refreshed;
    },
    updateSelectedKidsUser(
      state: HomeInitialState,
      action: PayloadAction<{ uid: string }>
    ) {
      state.selectedUserUid = action.payload.uid;
      const chatRooms = chatRoomAdapter
        .getSelectors()
        .selectAll(state.chatRooms);
      const selectedChatRoomId = chatRooms
        .filter(
          (chatRoom) =>
            chatRoom.type === "talk" &&
            chatRoom.members.filter((m) => m.uid === action.payload.uid)
              .length > 0
        )
        .shift().id;
      state.selectedChatRoomId = selectedChatRoomId;
      if (selectedChatRoomId) {
        const sync = chatRoomMessageSyncAdapter
          .getSelectors()
          .selectById(state.chatRoomMessageSync, selectedChatRoomId);
        if (sync.latestMessageCreatedAt) {
          chatRoomMessageSyncAdapter.updateOne(state.chatRoomMessageSync, {
            id: selectedChatRoomId,
            changes: { latestReadAt: dayjs().format("YYYY-MM-DDTHH:mm:ssZ") },
          });
        }
      }
    },
    updateChatRoomMessageLastSyncAt(
      state: HomeInitialState,
      action: PayloadAction<{
        chatRoomId: string;
        uid: string;
        lastMessageSyncAt: string;
      }>
    ) {
      chatRoomMessageSyncAdapter.upsertOne(state.chatRoomMessageSync, {
        id: action.payload.chatRoomId,
        uid: action.payload.uid,
        lastReadAt: null,
        latestMessage: null,
        latestMessageCreatedAt: null,
        lastSyncAt: action.payload.lastMessageSyncAt,
      });
    },
    insertChatRoomMessage(
      state: HomeInitialState,
      action: PayloadAction<{ uid: string; message: ChatRoomMessage }>
    ) {
      const { uid, message } = action.payload;
      chatRoomMessageAdapter.addOne(state.chatRoomMessages, message);
      const latestMessageCreatedAt =
        message.user_uid === uid ? null : message.created_at;
      if (state.selectedChatRoomId === message.chat_room_id) {
        chatRoomMessageSyncAdapter.updateOne(state.chatRoomMessageSync, {
          id: message.chat_room_id,
          changes: {
            latestReadAt: message.created_at,
            latestMessage: message,
            latestMessageCreatedAt: latestMessageCreatedAt,
          },
        });
      } else {
        chatRoomMessageSyncAdapter.updateOne(state.chatRoomMessageSync, {
          id: message.chat_room_id,
          changes: {
            latestMessage: message,
            latestMessageCreatedAt: latestMessageCreatedAt,
          },
        });
      }
    },
    bulkInsertChatRoomMessage(
      state: HomeInitialState,
      action: PayloadAction<{ uid: string; messages: ChatRoomMessage[] }>
    ) {
      const { uid, messages } = action.payload;

      if (messages.length > 0) {
        const lastMessage = messages[messages.length - 1];
        const chatRoom = chatRoomAdapter
          .getSelectors()
          .selectById(state.chatRooms, lastMessage.chat_room_id);
        chatRoomMessageSyncAdapter.upsertOne(state.chatRoomMessageSync, {
          id: lastMessage.chat_room_id,
          uid,
          lastSyncAt: lastMessage.created_at,
          latestReadAt: chatRoom.last_read_at,
          lastReadAt: chatRoom.last_read_at,
          latestMessage: messages[0],
          latestMessageCreatedAt:
            messages[0].user_uid === uid ? null : messages[0].created_at,
        });
      }

      chatRoomMessageAdapter.upsertMany(state.chatRoomMessages, messages);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getUserChatRooms.fulfilled,
        (state: HomeInitialState, action: PayloadAction<any>) => {
          const talkChatRooms = action.payload.chat_rooms.filter(
            (r) => r.type === "talk"
          );
          chatRoomAdapter.setAll(state.chatRooms, talkChatRooms);
          if (talkChatRooms.length > 0) {
            talkChatRooms.forEach((chat_room) =>
              chatRoomMessageSyncAdapter.upsertOne(state.chatRoomMessageSync, {
                id: chat_room.id,
                uid: chat_room.uid,
                lastReadAt: null,
                latestMessage: null,
                latestMessageCreatedAt: null,
                lastSyncAt: null,
              })
            );
          }
        }
      )
      .addCase(
        getChatRoomMessages.fulfilled,
        (state: HomeInitialState, action: PayloadAction<any>) => {
          const { messages } = action.payload;
          if (messages.length > 0) {
            chatRoomMessageSyncAdapter.updateOne(state.chatRoomMessageSync, {
              id: state.selectedChatRoomId,
              changes: {
                lastSyncAt: messages[messages.length - 1].created_at,
              },
            });

            chatRoomMessageAdapter.addMany(state.chatRoomMessages, messages);
          }
        }
      )
      .addCase(
        markChatRoomMessage.fulfilled,
        (state: HomeInitialState, action: PayloadAction<any>) => {
          const { chat_rooms_read_at } = action.payload;
          (chat_rooms_read_at as {
            chat_room_id: string;
            uid: string;
            last_read_at: string;
          }[]).forEach((read_at) => {
            chatRoomMessageSyncAdapter.updateOne(state.chatRoomMessageSync, {
              id: read_at.chat_room_id,
              changes: {
                lastReadAt: read_at.last_read_at,
                latestReadAt: read_at.last_read_at, // sync time with server
              },
            });
          });
        }
      );
  },
});

export const actions = { ...homeSlice.actions };

export default homeSlice.reducer;
