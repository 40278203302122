import { useState } from "react";
import { useSelector } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import I18n from "i18n-js";
import { Box, Stack, Button, MobileStepper } from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { RootState } from "app/store";
import Constants from "common/constant";
import { updateOnboarding } from "features/onboarding/api";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    src: `${Constants.assetHost}/assets/images/parents_onboarding_01.jpg`,
  },
  {
    src: `${Constants.assetHost}/assets/images/parents_onboarding_02.jpg`,
  },
  {
    src: `${Constants.assetHost}/assets/images/parents_onboarding_03.jpg`,
  },
  {
    src: `${Constants.assetHost}/assets/images/parents_onboarding_04.jpg`,
  },
  {
    src: `${Constants.assetHost}/assets/images/parents_onboarding_05.jpg`,
  },
  {
    src: `${Constants.assetHost}/assets/images/parents_onboarding_06.jpg`,
  },
  {
    src: `${Constants.assetHost}/assets/images/parents_onboarding_07.jpg`,
  },
];

export const Onboarding = ({ navigation }) => {
  const maxSteps = images.length;
  const [activeStep, setActiveStep] = useState(0);
  const user = useSelector((state: RootState) => state.appConfig.appUser.main);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const handleStart = async () => {
    await updateOnboarding({ uid: user.uid });
    navigation.navigate("Main", {
      screen: I18n.t("MSG_SCREEN_NAME_HOME"),
      params: { kids_main_uid: "test" },
    });
  };

  return (
    <Box className="w-full h-full">
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        className="w-full h-full"
      >
        <AutoPlaySwipeableViews
          axis="x"
          interval={6000}
          className="w-full h-full"
          index={activeStep}
          containerStyle={{ width: "100%", height: "100%" }}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {images.map((image, index) => (
            <div className="h-full" key={index}>
              {Math.abs(activeStep - index) <= 2 ? (
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  overflow="hidden"
                  className="w-full h-full"
                >
                  <img
                    alt="onboarding slide"
                    src={image.src}
                    className="w-full h-full object-contain"
                  />
                </Stack>
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>

        <Box className="!absolute bottom-[36px]">
          <Button
            variant="contained"
            onClick={handleStart}
            className="bg-white text-textcolor/black"
          >
            {I18n.t("MSG_ONBOARDING_START_BTN")}
          </Button>
        </Box>

        <MobileStepper
          variant="dots"
          steps={6}
          position="bottom"
          className="w-full absolute bg-transparent"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              {I18n.t("MSG_ONBOARDING_NAVI_NEXT")}
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              <KeyboardArrowLeft />
              {I18n.t("MSG_ONBOARDING_NAVI_BEFORE")}
            </Button>
          }
        />
      </Stack>
    </Box>
  );
};
