import { SnackbarAlert } from "features/alert";
import { ProjectPreview } from "features/preview";

export const DialogPage = () => {
  return (
    <>
      <SnackbarAlert />
      <ProjectPreview />
    </>
  );
};
