/* eslint-disable no-new-func */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Stack, Dialog, CircularProgress } from "@mui/material";
import { GameCase } from "app/types";
import { RootState } from "app/store";
import Constants from "common/constant";
import { useScale } from "common/utils";
import { FadeTransition } from "common/elements";
import { Menu } from "features/preview/Menu";

export const RuntimeGameCaseManager = {
  [GameCase.GRAY]: "middle_gray_case.png",
  [GameCase.SOFT_YELLOW]: "middle_soft_yellow_case.png",
  [GameCase.GREEN]: "middle_green_case.png",
  [GameCase.CYAN]: "middle_cyan_case.png",
  [GameCase.BLUE]: "middle_blue_case.png",
  [GameCase.MILK]: "middle_milk_case.png",
  [GameCase.PINK]: "middle_pink_case.png",
  [GameCase.ORANGE]: "middle_orange_case.png",
  [GameCase.SOFT_PINK]: "middle_soft_pink_case.png",
};

// Dialogはrootの下にないので、tailwindが効かない！
export const ProjectPreview = () => {
  const { scale } = useScale();
  const screenScale = 1000 / 1024;
  const [loading, setLoading] = useState(true);
  const preview = useSelector(
    (state: RootState) => state.appConfig.dialog.project_preview
  );
  const GameCaseComponent =
    RuntimeGameCaseManager[preview.args?.gameCase ?? GameCase.GRAY];

  useEffect(() => {
    if (preview.value) {
      setLoading(true);
    }
  }, [preview.value]);

  return (
    <Dialog
      fullScreen
      maxWidth={false}
      open={preview.value}
      sx={{ zIndex: 9998 }}
      TransitionComponent={FadeTransition}
      PaperProps={{
        style: { backgroundColor: "transparent", overflow: "hidden" },
      }}
      transitionDuration={{ enter: 500, exit: 500 }}
    >
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
      >
        <img
          alt="じゆうにつくる背景"
          src={`${Constants.assetHost}/assets/images/bg_creator.png`}
          className="absolute inset-0 object-cover w-full h-full z-[-1]"
        />

        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          flex={1}
          width={1116 * scale}
          height={844 * scale}
          position="absolute"
          sx={{
            filter: "drop-shadow(0px 0px 3px rgba(63, 74, 97, 0.3))",
          }}
        >
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            flex={1}
            width={1116}
            height={844}
            sx={{ transform: `scale(${scale})` }}
          >
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              width={1036 * screenScale}
              height={780 * screenScale}
              position="absolute"
              top={39}
              left={(1116 - 1036 * screenScale) / 2}
            >
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                width={1036}
                height={780}
                sx={{ transform: `scale(${screenScale})` }}
              >
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  width={1024}
                  height={768}
                  border="solid 6px #667786"
                  borderRadius={16}
                  boxSizing="content-box"
                  overflow="hidden"
                >
                  <iframe
                    frameBorder="0"
                    style={{ width: "100%", height: "100%", border: "none" }}
                    title="Live Preview"
                    allow="microphone; camera;"
                    onLoad={(e) => setLoading(false)}
                    src={`${Constants.runtimeHost}/?project_id=${preview.args?.projectId}`}
                  />
                  {loading && (
                    <Stack
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      position="absolute"
                      top={0}
                      left={0}
                      width="100%"
                      height="100%"
                    >
                      <CircularProgress />
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </Stack>

            <Stack
              direction="column"
              alignItems="center"
              justifyContent="space-between"
              height={844}
            >
              <img
                alt="game case"
                src={`${Constants.assetHost}/assets/images/${GameCaseComponent}`}
                style={{ width: "100%", height: "100%" }}
              />
              <img
                alt="アルゴリズム学ぶソフト"
                src={`${Constants.assetHost}/assets/images/course_soft_algorithm.png`}
                style={{
                  width: 133,
                  height: 168,
                  position: "absolute",
                  top: -20,
                  left: 87,
                  zIndex: -1,
                }}
              />
            </Stack>
          </Stack>
        </Stack>
        <Menu />
      </Stack>
    </Dialog>
  );
};
