const translations = {};

translations["MSG_APP_ERROR_TITLE"] = "エラー";
translations["MSG_APP_ERROR_ACCOUNT_NOT_EXISTS_TITLE"] =
  "アカウントが存在しません。\n先にアカウント登録をしてください。";
translations["MSG_APP_ERROR_SIGN_IN_FAILED"] =
  "メールアドレスかパスワードが正しくありません。";
translations["MSG_APP_ERROR_EMAIL_NOT_VALID"] =
  "有効なメールアドレスを入力してください。";
translations["MSG_APP_ERROR_EMAIL_ALREADY_IN_USE"] =
  "入力されたメールアドレスは既に使用されています。";
translations["MSG_APP_ERROR_PASSWORD_TOO_SHORT"] =
  "6文字以上のパスワードを入力してください。";
translations["MSG_APP_ERROR_PASSWORD_CONFIRM_NOT_VALID"] =
  "パスワードが一致していません。";

translations["MSG_APP_BACK_BTN"] = "戻る";
translations["MSG_APP_NEXT_BTN"] = "次へ";
translations["MSG_APP_CONFIRM_BTN"] = "確定";
translations["MSG_APP_CLOSE_BTN"] = "閉じる";

translations["MSG_SIGN_NOTICE"] =
  "『みまもりアプリ』は 『まなんでパズル』のアカウントでご利用いただけます。\n\n※『まなんでパズル』のアカウントをお持ちでない方は、『まなんでパズル』でアカウントを作成してからご利用ください。";
translations["MSG_SIGN_IN"] = "ログイン";
translations["MSG_SIGN_OTHER_ACCOUNT"] = "ソーシャルアカウントでログイン";
translations["MSG_SIGN_IN_WITH_GOOGLE"] = "Googleで続ける";
translations["MSG_SIGN_IN_WITH_APPLE"] = "Appleで続ける";
translations["MSG_SIGN_IN_WITH_MAIL"] = "メールでログイン";
translations["MSG_SIGN_IN_BTN"] = "ログイン";
translations["MSG_SIGN_USER_MAIL"] = "メールアドレス";
translations["MSG_SIGN_USER_PASSWORD"] = "パスワード";
translations["MSG_SIGN_IN_FORGOT_PASSWORD"] =
  "パスワードを忘れた場合は「こちら」";
translations["MSG_SIGN_IN_FORGOT_PASSWORD_TITLE"] = "パスワードリセット";
translations["MSG_SIGN_IN_FORGOT_PASSWORD_SEND_BTN"] = "送信";
translations["MSG_SIGN_IN_FORGOT_PASSWORD_SUCCESS_TITLE"] = "送信完了";
translations["MSG_SIGN_IN_FORGOT_PASSWORD_SUCCESS_CONTENT"] =
  "指定したメールアドレスへパスワードリセットのリンクを送りました。";
translations["MSG_SIGN_CHANGE_PASSWORD_LABEL_CURRENT_PASSWORD"] =
  "現在のパスワード";
translations["MSG_SIGN_CHANGE_PASSWORD_LABEL_NEW_PASSWORD"] =
  "新しいパスワード";
translations["MSG_SIGN_CHANGE_PASSWORD_LABEL_NEW_PASSWORD_CONFIRM"] =
  "パスワードを確認";
translations["MSG_SIGN_IN_CHANGE_PASSWORD_SEND_BTN"] = "変更";
translations["MSG_SIGN_IN_CHANGE_PASSWORD_SUCCESS_TITLE"] = "変更完了";
translations["MSG_SIGN_IN_CHANGE_PASSWORD_SUCCESS_CONTENT"] =
  "パスワード変更成功しました。";
translations["MSG_SIGN_IN_CHANGE_PASSWORD_CURRENT_PASSWORD_WRONG"] =
  "入力したパスワードが間違っています。";

translations["MSG_ONBOARDING_START_BTN"] = "はじめる";
translations["MSG_ONBOARDING_NAVI_NEXT"] = "次へ";
translations["MSG_ONBOARDING_NAVI_BEFORE"] = "前へ";

translations["MSG_SCREEN_NAME_HOME"] = "トーク";
translations["MSG_SCREEN_NAME_NOTIFICATION"] = "お知らせ";
translations["MSG_SCREEN_USER_NOT_LINKED"] =
  "ユーザーが連携されていません\n・このアカウントにはユーザーが1人も登録されていません。\n・『まなんでパズル』に、このアカウントでログインしていただき、ユーザーを連携（登録）してください。";
translations["MSG_MENU_PROFILE"] = "プロフィールの変更";
translations["MSG_MENU_ONBOARDING"] = "ヘルプ（アプリの紹介）";
translations["MSG_MENU_CHANGE_PASSWORD"] = "パスワード変更";
translations["MSG_MENU_APP_ABOUT"] = "アプリについて";
translations["MSG_MENU_LOGOUT"] = "ログアウト";

translations["MSG_CHAT_SEND_BTN"] = "送る";
translations["MSG_CHAT_USER_DELETED_ALERT"] = "ユーザーが削除されたました！";
translations["MSG_CHAT_STAMP_CHARACTER"] = "キャラクター";
translations["MSG_CHAT_STAMP_GAME"] = "活動・遊び";
translations["MSG_CHAT_STAMP_ANIMAL"] = "生き物";
translations["MSG_CHAT_STAMP_EVENT"] = "イベント";
translations["MSG_CHAT_STAMP_INTERNET"] = "インターネット";
translations["MSG_CHAT_STAMP_MONEY"] = "経済・お金";
translations["MSG_CHAT_STAMP_GOODS"] = "生活雑貨";
translations["MSG_CHAT_STAMP_NATURE"] = "自然・植物";
translations["MSG_CHAT_STAMP_PERSON"] = "人物";
translations["MSG_CHAT_STAMP_BUILDING"] = "建物";
translations["MSG_CHAT_STAMP_FOOD"] = "食べ物";
translations["MSG_CHAT_STAMP_ELECTRONIC"] = "家電・通信";
translations["MSG_CHAT_STAMP_SMILE"] = "にこちゃん";
translations["MSG_CHAT_STAMP_VEHICLE"] = "乗り物";
translations["MSG_CHAT_STAMP_STATIONARY"] = "文房具";
translations["MSG_CHAT_STAMP_OTHERS"] = "その他";
translations["MSG_CHAT_MESSAGE_PARENTS_TALK_WELCOME_TEXT"] = "が参加しました。";
translations["MSG_CHAT_MESSAGE_RUN_APP_BTN"] = "うごかす";
translations["MSG_CHAT_MESSAGE_PUBLISHED_PROJECT_MESSAGE"] =
  "アプリ「%{name}」を公開しました。";
translations["MSG_PROJECT_PREVIEW_MENU_CLOSE_BTN"] = "この画面を\n閉じる";

translations["MSG_PROFILE_NAME"] = "表示名";
translations["MSG_PROFILE_SAVE_BTN"] = "保存";
translations["MSG_PROFILE_DEFAULT_NAME"] = "保護者";

translations["MSG_APP_ABOUT_GO_TO_SITE"] = "『みまもりアプリ』のサイトへ";
translations["MSG_APP_ABOUT_TERMS_OF_SERVICE"] = "ご利用規約";
translations["MSG_APP_ABOUT_PRIVACY_POLICY"] = "プライバシーポリシー";
translations["MSG_APP_ABOUT_INQUIRY"] = "お問い合わせ";
translations["MSG_APP_ABOUT_DELETE_PARENTS_USER_BTN"] = "アカウントの削除";
translations["MSG_APP_ABOUT_DELETE_PARENTS_USER_TITLE"] =
  "アカウントの削除について";
translations["MSG_APP_ABOUT_DELETE_PARENTS_USER_CONTENTS"] =
  "・『みまもりアプリ』では、アカウントの削除はできません。\n・『みまもりアプリ』でご利用中のアカウントの削除をしたい場合は、『まなんでパズル』にてアカウントの削除を行ってください。\n・『まなんでパズル』でアカウントを削除すると、『まなんでパズル』と『みまもりアプリ』の全てのアカウント情報とユーザー情報が削除できます。";

export default translations;
