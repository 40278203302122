import { useDispatch, useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import { Snackbar, Typography } from "@mui/material";
import { RootState } from "app/store";
import { DialogType } from "app/types";
import { actions } from "app/appConfigSlice";

export const SnackbarAlert = () => {
  const dispatch = useDispatch();
  const snackbarAlert = useSelector(
    (state: RootState) => state.appConfig.dialog.snackbar_alert
  );
  const handleCloseAlert = () => {
    dispatch(
      actions.updateDialog({
        type: DialogType.SNACKBAR_ALERT,
        value: false,
        args: snackbarAlert.args,
      })
    );
  };

  return (
    <Snackbar
      open={snackbarAlert.value}
      autoHideDuration={1000}
      sx={{ top: { xs: 100 }, zIndex: 10000, width: "100%" }}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={handleCloseAlert}
    >
      <Alert
        elevation={6}
        severity={snackbarAlert.args?.type}
        variant="filled"
        onClose={handleCloseAlert}
      >
        <Typography align="center" variant="body1" lineHeight={1}>
          {snackbarAlert.args?.message}
        </Typography>
      </Alert>
    </Snackbar>
  );
};
