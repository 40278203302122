export const stampCategories = [
  "stamps/character",
  "stamps/game",
  "stamps/animal",
  "stamps/event",
  "stamps/internet",
  "stamps/money",
  "stamps/goods",
  "stamps/nature",
  "stamps/person",
  "stamps/building",
  "stamps/food",
  "stamps/electronic",
  "stamps/smile",
  "stamps/vehicle",
  "stamps/stationary",
  "stamps/others",
];
