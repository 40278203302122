import { useRef, useState, useEffect, useCallback } from "react";
import { useScale } from "common/utils";
import { AppPlatform } from "./appConfigSlice";

export const useNativeKeyboardMessage = () => {
  const { width, height } = useScale();
  const [keyboardHeight, _setKeyboardHeight] = useState(0);
  const keyboardHeightRef = useRef(keyboardHeight);
  const setKeyboardHeight = (value: number) => {
    _setKeyboardHeight(value);
    keyboardHeightRef.current = value;
  };

  const handleUpdateKeyboardPosition = useCallback(
    (enabled: boolean, offset: number) => {
      if (
        window.ReactNativeWebView &&
        (window.parentsAppPlatform === AppPlatform.Android ||
          window.parentsAppPlatform === AppPlatform.Amazon)
      ) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            command: "keyboardAvoidingViewConfig",
            data: {
              enabled: enabled,
              behavior: "position",
              keyboardVerticalOffset: offset,
            },
          })
        );
      }
    },
    []
  );

  const handleMessage = (message) => {
    try {
      const { command, data } = JSON.parse(message.data);
      switch (command) {
        case "keyboardDidShow":
          const { event } = data;
          if (event.height !== keyboardHeightRef.current) {
            localStorage.setItem("keyboard_height", event.height);
            setKeyboardHeight(event.height);
          }
          break;
        case "keyboardDidHide":
          handleUpdateKeyboardPosition(false, 0);
          break;
        default:
          break;
      }
    } catch (error) {
      console.warn(JSON.stringify(message));
      console.warn(JSON.stringify(error));
    }
  };

  useEffect(() => {
    const handleInput = (event: Event) => {
      const element = event.target as HTMLElement;
      if (
        element &&
        (element.tagName === "INPUT" || element.tagName === "TEXTAREA")
      ) {
        const rect = element.getBoundingClientRect();
        if (keyboardHeightRef.current > 0) {
          const enabled = height - rect.bottom < keyboardHeightRef.current;
          const offset = enabled ? rect.bottom - height + 10 : 0;
          handleUpdateKeyboardPosition(enabled, offset);
        }
      }
    };
    if (
      window.ReactNativeWebView &&
      (window.parentsAppPlatform === AppPlatform.Android ||
        window.parentsAppPlatform === AppPlatform.Amazon)
    ) {
      window.addEventListener("focus", handleInput, true);
    }
    return () => {
      if (
        window.ReactNativeWebView &&
        (window.parentsAppPlatform === AppPlatform.Android ||
          window.parentsAppPlatform === AppPlatform.Amazon)
      ) {
        window.removeEventListener("focus", handleInput, true);
      }
    };
  }, [width, height, handleUpdateKeyboardPosition]);

  useEffect(() => {
    if ("ReactNativeWebView" in window) {
      if (
        window.parentsAppPlatform === AppPlatform.iOS ||
        window.parentsAppPlatform === AppPlatform.Android
      ) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            command: "getNotifications",
          })
        );
      }

      if (window.parentsAppPlatform === AppPlatform.iOS) {
        window.addEventListener("message", handleMessage);
      } else {
        document.addEventListener("message", handleMessage);
      }

      if (
        window.parentsAppPlatform === AppPlatform.Android ||
        window.parentsAppPlatform === AppPlatform.Amazon
      ) {
        const keyboardHeight = localStorage.getItem("keyboard_height");
        if (keyboardHeight) {
          setKeyboardHeight(Number(keyboardHeight));
        }
      }
    }

    return () => {
      if ("ReactNativeWebView" in window) {
        if (window.parentsAppPlatform === "ios") {
          window.removeEventListener("message", handleMessage);
        } else {
          document.removeEventListener("message", handleMessage);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleUpdateKeyboardPosition(false, 0);
  }, [width, height, handleUpdateKeyboardPosition]);
};
