import { useMemo, useState, MouseEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Menu,
  List,
  Button,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from "@mui/material";
import dayjs from "dayjs";
import { AccountType } from "app/types";
import { RootState, AppDispatch } from "app/store";
import { UserAvatar } from "common/elements";
import {
  actions,
  selectAllChatRooms,
  selectAllChatRoomMessageSync,
} from "features/home/slice";
import { UserSelectMenuItem } from "./UserSelectMenuItem";

export const UserSelectMenu = () => {
  const dispatch = useDispatch<AppDispatch>();
  const chatRooms = useSelector(selectAllChatRooms);
  const selectedUserUid = useSelector(
    (state: RootState) => state.home.selectedUserUid
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const chatRoomMessageSync = useSelector(selectAllChatRoomMessageSync);
  const hasUnreadMessage =
    chatRoomMessageSync.filter(
      (sync) =>
        dayjs(sync.latestMessageCreatedAt, "YYYY-MM-DDTHH:mm:ssZ").diff(
          dayjs(sync.latestReadAt, "YYYY-MM-DDTHH:mm:ssZ")
        ) > 0 ||
        (sync.latestReadAt === null && sync.latestMessageCreatedAt !== null)
    ).length > 0;

  const users = useSelector(
    (state: RootState) => state.appConfig.appUser.users
  );
  const kidsUsers = useMemo(
    () => users.filter((u) => u.account_type !== AccountType.PARENTS),
    [users]
  );
  const selectedKidsUser = useMemo(
    () => kidsUsers.filter((u) => u.uid === selectedUserUid).shift(),
    [kidsUsers, selectedUserUid]
  );

  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    if (kidsUsers.length > 0 && chatRooms.length > 0) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectChatRoom = (chatRoomId: string, uid: string) => () => {
    dispatch(actions.updateSelectedKidsUser({ uid }));
    setAnchorEl(null);
  };

  return (
    <Box>
      <Button
        id="selecter-user-menu-button"
        aria-controls={Boolean(anchorEl) ? "select-user-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl) ? "true" : undefined}
        disableElevation
        sx={{
          width: 240,
          paddingX: 2,
          paddingY: 1,
          bgcolor: "transparent",
          color: "text.primary",
          textTransform: "none",
          justifyContent: "flex-start",
          "&.MuiButtonBase-root:hover": {
            bgcolor: "transparent",
            opacity: 0.7,
          },
        }}
        onClick={handleOpenMenu}
      >
        <List disablePadding>
          {kidsUsers.length > 0 && (
            <ListItem disablePadding>
              {hasUnreadMessage && (
                <Box
                  width={20}
                  height={20}
                  position="absolute"
                  top={5}
                  left={-6}
                  bgcolor="#FC6560"
                  borderRadius={20}
                  zIndex={50}
                />
              )}
              <ListItemAvatar className="mr-4">
                <UserAvatar src={selectedKidsUser?.icon} />
              </ListItemAvatar>
              <ListItemText primary={selectedKidsUser?.display_name} />
            </ListItem>
          )}
        </List>
      </Button>

      <Menu
        id="select-user-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "selecter-user-menu-button",
        }}
      >
        {kidsUsers
          .filter((u) => u.account_type !== AccountType.PARENTS)
          .map((user, index) => (
            <UserSelectMenuItem
              key={index}
              uid={user.uid}
              chatRoomId={
                chatRooms
                  .filter(
                    (chatRoom) =>
                      chatRoom.type === "talk" &&
                      chatRoom.members.filter((m) => m.uid === user.uid)
                        .length > 0
                  )
                  .shift()?.id
              }
              handleSelectChatRoom={handleSelectChatRoom}
            />
          ))}
      </Menu>
    </Box>
  );
};
