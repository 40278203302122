import { useState, useEffect } from "react";
import {
  getAuth,
  OAuthProvider,
  GoogleAuthProvider,
  signInWithCredential,
} from "firebase/auth";
import {
  Stack,
  Dialog,
  Button,
  Backdrop,
  Typography,
  CircularProgress,
} from "@mui/material";
import I18n from "i18n-js";
import * as jose from "jose";
import AppleIcon from "@mui/icons-material/Apple";
import { signInParentsAccount } from "app/api";
import { AppPlatform } from "app/appConfigSlice";
import Constants from "common/constant";
import { FadeTransition } from "common/elements";
import { GoogleSignNa } from "./asset";

const UserNotExistsError = ({ close }: { close: () => void }) => {
  return (
    <Dialog
      fullScreen
      open={true}
      maxWidth={false}
      sx={{ zIndex: 9998 }}
      TransitionComponent={FadeTransition}
      PaperProps={{
        style: { backgroundColor: "transparent", overflow: "hidden" },
      }}
      transitionDuration={{ enter: 500, exit: 500 }}
    >
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
      >
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="space-evenly"
          width="300px"
          height="200px"
          bgcolor="white"
          sx={{ borderRadius: 4 }}
        >
          <Typography variant="subtitle1" whiteSpace="pre" align="center">
            {I18n.t("MSG_APP_ERROR_ACCOUNT_NOT_EXISTS_TITLE")}
          </Typography>
          <Button size="large" variant="contained" onClick={close}>
            {I18n.t("MSG_APP_CLOSE_BTN")}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export const SSO = () => {
  const [loading, setLoading] = useState(false);
  const [notExists, setNotExists] = useState(false);
  const nonce = Math.random().toString(36).substring(2, 10);

  const handleFirebaseGoogleSignIn = async (idToken: string) => {
    const decode = jose.decodeJwt(idToken);
    const response = await signInParentsAccount({
      key: Constants.apiAuthKey,
      provider: "google.com",
      email: decode["email"] as string,
    });
    const { exist } = response.data;
    if (exist) {
      setLoading(true);
      const auth = getAuth();
      const credential = GoogleAuthProvider.credential(idToken);
      signInWithCredential(auth, credential)
        .then((v) => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      setNotExists(true);
    }
  };

  const handleFirebaseAppleSignIn = async (idToken: string) => {
    const decode = jose.decodeJwt(idToken);
    const response = await signInParentsAccount({
      key: Constants.apiAuthKey,
      provider: "apple.com",
      email: decode["email"] as string,
    });
    const { exist } = response.data;
    if (exist) {
      setLoading(true);
      const auth = getAuth();
      const provider = new OAuthProvider("apple.com");
      provider.addScope("email");
      const credential = provider.credential({
        idToken: idToken,
        rawNonce: nonce,
      });
      signInWithCredential(auth, credential)
        .then((v) => {
          setLoading(false);
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
          setLoading(false);
        });
    } else {
      setLoading(false);
      setNotExists(true);
    }
  };

  const handleGoogleCredentialResponse = (response) => {
    handleFirebaseGoogleSignIn(response.credential);
  };

  const handleAppleSignInSuccess = (event) => {
    handleFirebaseAppleSignIn(event.detail.authorization.id_token);
  };

  const handleAppleSignInFailure = (event) => {
    console.log(event.detail);
  };

  const handleMessage = (message) => {
    const { command, data } = JSON.parse(message.data);
    switch (command) {
      case "signIn":
        const { method } = data;
        switch (method) {
          case "google":
            if (data.idToken) {
              handleFirebaseGoogleSignIn(data.idToken);
            } else {
              console.error(data.result);
              setLoading(false);
            }
            break;
          case "apple":
            handleFirebaseAppleSignIn(data.idToken);
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const init_apple_sign = async () => {
      const encoder = new TextEncoder();
      const encodedNonce = encoder.encode(nonce);
      const hashBuffer = await crypto.subtle.digest("SHA-256", encodedNonce);
      const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
      const hashedNonce = hashArray
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("");

      //@ts-ignore
      AppleID.auth.init({
        clientId: "com.inexus.app.services.parents",
        scope: "email",
        redirectURI: process.env.REACT_APP_PUBLIC_URL,
        state: "parents",
        nonce: hashedNonce,
        usePopup: true,
      });
    };

    if ("ReactNativeWebView" in window) {
      if (window.parentsAppPlatform === "ios") {
        window.addEventListener("message", handleMessage);
      } else {
        document.addEventListener("message", handleMessage);
      }
    } else {
      init_apple_sign();

      document.addEventListener(
        "AppleIDSignInOnSuccess",
        handleAppleSignInSuccess
      );

      document.addEventListener(
        "AppleIDSignInOnFailure",
        handleAppleSignInFailure
      );

      //@ts-ignore
      google.accounts.id.initialize({
        client_id: Constants.googleSignClientId,
        callback: handleGoogleCredentialResponse,
      });
      //@ts-ignore
      google.accounts.id.renderButton(document.getElementById("buttonDiv"), {
        type: "standard",
        theme: "outline",
        size: "large",
        width: "300",
        logo_alignment: "left",
        locale: "ja",
        text: "continue_with",
      });
    }

    return () => {
      if ("ReactNativeWebView" in window) {
        if (window.parentsAppPlatform === "ios") {
          window.removeEventListener("message", handleMessage);
        } else {
          document.removeEventListener("message", handleMessage);
        }
      } else {
        document.removeEventListener(
          "AppleIDSignInOnSuccess",
          handleAppleSignInSuccess
        );

        document.removeEventListener(
          "AppleIDSignInOnFailure",
          handleAppleSignInFailure
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGoogleSign = async () => {
    if ("ReactNativeWebView" in window) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          command: "signIn",
          data: {
            method: "google",
          },
        })
      );
    }
  };

  const handleAppleSign = () => {
    if ("ReactNativeWebView" in window) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          command: "signIn",
          data: {
            method: "apple",
          },
        })
      );
    }
  };

  const handleCloseNotExists = () => {
    setNotExists(false);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {notExists && <UserNotExistsError close={handleCloseNotExists} />}

      {window.ReactNativeWebView &&
      window.parentsAppPlatform === AppPlatform.iOS ? (
        <div
          onClick={handleAppleSign}
          className="flex-row-el flex-center w-full h-[40px] bg-[#000] rounded-lg cursor-pointer"
        >
          <AppleIcon sx={{ fontSize: 16, marginRight: 0.5, color: "white" }} />
          <p className="text-body text-[16px] text-white">
            {I18n.t("MSG_SIGN_IN_WITH_APPLE")}
          </p>
        </div>
      ) : (
        <div
          className="w-full h-[40px] cursor-pointer"
          id="appleid-signin"
          data-color="black"
          data-border="true"
          data-mode="center-align"
          data-logo-size="large"
          data-type="continue"
        />
      )}

      {window.ReactNativeWebView ? (
        <div
          onClick={handleGoogleSign}
          className="flex-row-el flex-center relative w-full h-[42px] bg-[#fff] rounded-md border-solid border-[1px] border-[#dadce0] cursor-pointer"
        >
          <div className="flex-col-el absolute top-0 left-0">
            <GoogleSignNa />
          </div>
          <div className="flex-col-el w-[40px] h-[40px]" />
          <p className="text-body text-[16px] text-textcolor/black">
            {I18n.t("MSG_SIGN_IN_WITH_GOOGLE")}
          </p>
        </div>
      ) : (
        <div id="buttonDiv"></div>
      )}
    </>
  );
};
