import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "common/apiClient";
import { fetchAuthToken } from "./Auth";

export const signInParentsAccount = async ({
  key,
  provider,
  email,
}: {
  key: string;
  provider: string;
  email: string;
}) => {
  return client({ token: key }).post("/v1/sign/parents", {
    provider,
    email,
  });
};

export const getUser = createAsyncThunk(
  "app/getUser",
  async ({ uid, env }: { uid: string; env: string }) => {
    const token = await fetchAuthToken();
    const response = await client({ token: token }).get(
      `/v1/user/${uid}?env=${env}`
    );
    return response.data.data;
  }
);

export const getUserConfig = createAsyncThunk(
  "app/getUserConfig",
  async ({ uid }: { uid: string }) => {
    const token = await fetchAuthToken();
    const response = await client({ token: token }).get(
      `/v1/user/config/${uid}`
    );
    return response.data.data;
  }
);

export const getNotifications = createAsyncThunk(
  "app/getNotifications",
  async () => {
    const token = await fetchAuthToken();
    const response = await client({ token: token }).get("/v1/notifications");
    return response.data.data;
  }
);
