import { configureStore } from "@reduxjs/toolkit";
import appConfigSlice from "./appConfigSlice";
import homeSlice from "features/home/slice";

export const store = configureStore({
  reducer: {
    appConfig: appConfigSlice,
    home: homeSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: process.env.NODE_ENV === "development",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
