import * as Sentry from "@sentry/react";
import { captureConsoleIntegration } from "@sentry/integrations";
import Constants from "common/constant";
import { useNativeKeyboardMessage } from "./useNativeKeyboardMessage";

Sentry.init({
  dsn: Constants.sentryDSN,
  integrations: [captureConsoleIntegration({ levels: ["error"] })],
  tracesSampleRate: 0.2,
  environment: Constants.sentryEnv,
  release: `${Constants.sentryEnv}-ver${Constants.major}.${Constants.minor}.${Constants.buildNumber}`,
});

export const useInitialApp = () => {
  useNativeKeyboardMessage();
};
