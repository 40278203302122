import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "common/apiClient";

export const getParentsLatestVersion = createAsyncThunk(
  "app/getParentsLatestVersion",
  async ({ key }: { key: string }) => {
    const response = await client({ token: key }).get(
      "/v1/release/parents/latest"
    );
    return response.data.data.releases;
  }
);
