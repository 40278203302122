import { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import I18n from "i18n-js";
import { Stack, Backdrop, CircularProgress } from "@mui/material";
import { RootState } from "app/store";
import { AuthContext } from "app/Auth";
import { AppPlatform } from "app/appConfigSlice";

export enum DeviceNotification {
  NOT_DETERMINED = "NOT_DETERMINED",
  AUTHORIZED = "AUTHORIZED",
  DENIED = "DENIED",
}

export const Top = ({ navigation }) => {
  const auth = useContext(AuthContext);
  const [notification, setNotification] = useState(true);
  const config = useSelector((state: RootState) => state.appConfig.config);

  const handleMessage = (message) => {
    try {
      const { command, data } = JSON.parse(message.data);
      const { status } = data;
      switch (command) {
        case "notifications":
          if (status === DeviceNotification.NOT_DETERMINED) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                command: "registerNotifications",
                data: { uid: auth.user.uid },
              })
            );
          }
          setNotification(false);
          break;
        default:
          setNotification(false);
          break;
      }
    } catch (error) {
      console.log("handleMessage error.");
      console.warn(error);
      console.warn(message);
      console.warn(JSON.stringify(message));
      console.warn(JSON.stringify(error));
    }
  };

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault();
      window.deferredPrompt = event;
    });

    if (
      window.ReactNativeWebView &&
      (window.parentsAppPlatform === AppPlatform.iOS ||
        window.parentsAppPlatform === AppPlatform.Android)
    ) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          command: "getNotifications",
        })
      );
      if (window.parentsAppPlatform === AppPlatform.iOS) {
        window.addEventListener("message", handleMessage);
      } else if (window.parentsAppPlatform === AppPlatform.Android) {
        document.addEventListener("message", handleMessage);
      } else {
        setNotification(false);
      }
    } else {
      setNotification(false);
    }
    return () => {
      if ("ReactNativeWebView" in window) {
        if (window.parentsAppPlatform === AppPlatform.iOS) {
          window.removeEventListener("message", handleMessage);
        } else if (window.parentsAppPlatform === AppPlatform.Android) {
          document.removeEventListener("message", handleMessage);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!notification) {
      if (config.onboarding) {
        navigation.navigate("Onboarding");
      } else {
        navigation.navigate("Main", {
          screen: I18n.t("MSG_SCREEN_NAME_HOME"),
        });
      }
    }
  }, [config, notification]);

  return (
    <Stack
      height="100%"
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={notification}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Stack>
  );
};
