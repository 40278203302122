import { useMemo } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { Box, MenuItem, ListItemText, ListItemAvatar } from "@mui/material";
import { RootState } from "app/store";
import { UserAvatar } from "common/elements";
import { selectChatRoomMessageSyncById } from "features/home/slice";

export const UserSelectMenuItem = (props: {
  uid: string;
  chatRoomId: string;
  handleSelectChatRoom: (chatRoomId: string, uid: string) => () => void;
}) => {
  const { uid, chatRoomId, handleSelectChatRoom } = props;
  const kidsUsers = useSelector(
    (state: RootState) => state.appConfig.appUser.users
  );
  const kidsUser = useMemo(
    () => kidsUsers.filter((u) => u.uid === uid).shift(),
    [kidsUsers, uid]
  );
  const chatRoomMessageSync = useSelector((state: RootState) =>
    selectChatRoomMessageSyncById(state, chatRoomId)
  );

  const hasUnreadMessage =
    dayjs(
      chatRoomMessageSync.latestMessageCreatedAt,
      "YYYY-MM-DDTHH:mm:ssZ"
    ).diff(dayjs(chatRoomMessageSync.latestReadAt, "YYYY-MM-DDTHH:mm:ssZ")) >
      0 ||
    (chatRoomMessageSync.latestReadAt === null &&
      chatRoomMessageSync.latestMessageCreatedAt !== null);

  return (
    <div>
      <MenuItem onClick={handleSelectChatRoom(chatRoomId, uid)}>
        {hasUnreadMessage && (
          <Box
            width={20}
            height={20}
            position="absolute"
            top={10}
            left={10}
            bgcolor="#FC6560"
            borderRadius={20}
            zIndex={50}
          />
        )}

        <ListItemAvatar sx={{ marginRight: "16px" }}>
          <UserAvatar src={kidsUser.icon} />
        </ListItemAvatar>
        <ListItemText primary={kidsUser.display_name} />
      </MenuItem>
    </div>
  );
};
