import I18n from "i18n-js";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { List, Stack, Divider, ListItem, ListItemText } from "@mui/material";

export const About = ({ navigation }) => {
  const handleOpenLink = (url: string) => () => {
    if ("ReactNativeWebView" in window) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ command: "openLink", data: { url: url } })
      );
    } else {
      window.open(url, "_blank");
    }
  };
  return (
    <Stack
      width="100%"
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <List sx={{ width: "100%" }}>
        <ListItem
          sx={{ cursor: "pointer" }}
          secondaryAction={<KeyboardArrowRightIcon color="disabled" />}
          onClick={handleOpenLink("https://inexus-co.com/mimamori-app/")}
        >
          <ListItemText primary={I18n.t("MSG_APP_ABOUT_GO_TO_SITE")} />
        </ListItem>
        <Divider />
        <ListItem
          sx={{ cursor: "pointer" }}
          secondaryAction={<KeyboardArrowRightIcon color="disabled" />}
          onClick={handleOpenLink("https://inexus-co.com/privacy-policy/")}
        >
          <ListItemText primary={I18n.t("MSG_APP_ABOUT_TERMS_OF_SERVICE")} />
        </ListItem>
        <Divider />
        <ListItem
          sx={{ cursor: "pointer" }}
          secondaryAction={<KeyboardArrowRightIcon color="disabled" />}
          onClick={handleOpenLink("https://inexus-co.com/privacy-policy/")}
        >
          <ListItemText primary={I18n.t("MSG_APP_ABOUT_PRIVACY_POLICY")} />
        </ListItem>
        <Divider />
        <ListItem
          sx={{ cursor: "pointer" }}
          secondaryAction={<KeyboardArrowRightIcon color="disabled" />}
          onClick={handleOpenLink("https://inexus-co.com/contact/")}
        >
          <ListItemText primary={I18n.t("MSG_APP_ABOUT_INQUIRY")} />
        </ListItem>
        <Divider />
        <ListItem
          sx={{ cursor: "pointer" }}
          secondaryAction={<KeyboardArrowRightIcon color="disabled" />}
          onClick={() => navigation.navigate("Unsubscribe")}
        >
          <ListItemText
            primary={I18n.t("MSG_APP_ABOUT_DELETE_PARENTS_USER_BTN")}
          />
        </ListItem>
      </List>
    </Stack>
  );
};
