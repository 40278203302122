import { useRef, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import I18n from "i18n-js";
import { List, Stack, Typography } from "@mui/material";
import { AppDispatch, RootState } from "app/store";
import {
  ChatMessageItem,
  OfficialChatMessageItem,
} from "features/home/message";
import { ChatRoomMemberType } from "features/home/types";
import { actions, selectAllChatRoomMessages } from "features/home/slice";

const MessageDate = (props: { timestamp: string }) => {
  const { timestamp } = props;
  return (
    <div className="w-full flex flex-row items-center justify-center">
      <Typography
        sx={{ display: "inline" }}
        component="span"
        variant="body2"
        className="text-gray/100 bg-gray/20 px-3 py-1 rounded-[12px]"
      >
        {dayjs(timestamp, "YYYY-MM-DD HH:mm:ssZ").format("MM/DD")}
      </Typography>
    </div>
  );
};

export const ChatMessageList = () => {
  const ref = useRef<HTMLUListElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const selectedUserUid = useSelector(
    (state: RootState) => state.home.selectedUserUid
  );
  const selectedChatRoomId = useSelector(
    (state: RootState) => state.home.selectedChatRoomId
  );
  const messages = useSelector(selectAllChatRoomMessages);
  const kidsUsers = useSelector(
    (state: RootState) => state.appConfig.appUser.users
  );
  const selectedKidsUser = useMemo(
    () => kidsUsers.filter((u) => u.uid === selectedUserUid).shift(),
    [kidsUsers, selectedUserUid]
  );
  const user = useSelector((state: RootState) => state.appConfig.appUser.main);
  const isRefreshed = useSelector((state: RootState) => state.home.isRefreshed);
  const chatRoomMessages = messages.filter(
    (message) => message.chat_room_id === selectedChatRoomId
  );

  useEffect(() => {
    if (ref.current) {
      if (isRefreshed) {
        ref.current.scrollTop = 200;
        dispatch(actions.updateIsRefreshed({ refreshed: false }));
      } else {
        ref.current.scrollTop = ref.current.scrollHeight;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChatRoomId, ref.current, messages]);

  return (
    <Stack width="100%" height="100%" overflow="hidden">
      {selectedUserUid === null && (
        <Stack
          width="100%"
          height="100%"
          padding={4}
          position="absolute"
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            width="100%"
            align="left"
            component="p"
            variant="body2"
            className="text-gray/100"
            whiteSpace="pre-line"
          >
            {I18n.t("MSG_SCREEN_USER_NOT_LINKED")}
          </Typography>
        </Stack>
      )}

      <List ref={ref} sx={{ overflow: "auto", height: "100%", paddingLeft: 2 }}>
        {chatRoomMessages.map((message, index) => {
          const isFromUser = message.user_uid === user.uid;
          const isOfficial = message.user_type === ChatRoomMemberType.OFFICIAL;
          return (
            <div key={index}>
              {index === 0 ? (
                <MessageDate timestamp={message.created_at} />
              ) : (
                dayjs(
                  dayjs(message.created_at, "YYYY-MM-DD HH:mm:ssZ").format(
                    "YYYY-MM-DD"
                  )
                ).diff(
                  dayjs(
                    dayjs(
                      chatRoomMessages[index - 1].created_at,
                      "YYYY-MM-DD HH:mm:ssZ"
                    ).format("YYYY-MM-DD")
                  ),
                  "days"
                ) > 0 && <MessageDate timestamp={message.created_at} />
              )}
              {isOfficial ? (
                <OfficialChatMessageItem message={message} />
              ) : (
                <ChatMessageItem
                  isFromUser={isFromUser}
                  name={
                    isFromUser
                      ? user.display_name
                      : selectedKidsUser.display_name
                  }
                  icon={isFromUser ? user.icon : selectedKidsUser.icon}
                  message={message}
                />
              )}
            </div>
          );
        })}
      </List>
    </Stack>
  );
};
