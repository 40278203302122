import { useWindowDimensions } from "react-native";
import platfrom from "platform";
import { BASE_WIDTH, BASE_HEIGHT } from "common/constant";

export const calScale = (width: number, height: number) => {
  const scaleX = width / BASE_WIDTH;
  const scaleY = height / BASE_HEIGHT;

  return scaleX < scaleY ? scaleX : scaleY;
};

export const useScale = () => {
  const { width, height } = useWindowDimensions();
  const scale = calScale(width, height);
  const ratio = width / height;
  return { width, height, scale, ratio };
};

export const getUserLanguage = () => {
  const userLanguage =
    window.parentsAppHostLanguage ?? window.navigator.language;
  const code1 = userLanguage.split("-")[0];

  if (code1 === "en") {
    return "en";
  } else if (code1 === "ja") {
    return "ja";
  } else if (code1 === "zh") {
    const code2 = userLanguage.split("-")[1];
    if (code2 === "Hans") {
      return "zh_cn";
    } else if (code2 === "Hant") {
      return "zh_tw";
    } else {
      return "zh_cn";
    }
  } else {
    return "en";
  }
};

export const insideInstalledApp = () =>
  window.matchMedia("(display-mode: standalone)").matches ||
  ("standalone" in window.navigator && window.navigator["standalone"] === true);

export const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    window.location.hostname === "[::1]" ||
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const isMobile = () => {
  const isIos = platfrom.os.family === "iOS";
  const isFire = FireModels.test(platfrom.os.family);
  const isAndroid = platfrom.os.family === "Android";
  return isIos || isAndroid || isFire;
};

export const isChrome = () => {
  return /Chrome/.test(platfrom.name);
};

export const isIos = () => {
  return platfrom.os.family === "iOS";
};

export const isAndroid = () => {
  return platfrom.os.family === "Android";
};

const FireModels = /KFQUWI|KFTRWI|KFTRPWI|KFONWI|KFMAWI|KFMUWI|KFKAWI|KFSUWI|KFDOWI|KFAUWI|KFGIWI|KFTBWI|KFMEWI|KFFOWI|KFSAWA|KFSAWI|KFASWI|KFARWI|KFTHWA|KFTHWI|KFAPWA|KFAPWI|KFSOWI|KFOT|KFTT|KFJWA|KFJWI|Kindle Fire/;

export const isFire = () => {
  return FireModels.test(platfrom.product);
};
