import { useState, FormEvent } from "react";
import { useDispatch } from "react-redux";
import {
  getAuth,
  sendPasswordResetEmail,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import {
  Box,
  Stack,
  Button,
  Backdrop,
  Container,
  CircularProgress,
} from "@mui/material";
import I18n from "i18n-js";
import { DialogType } from "app/types";
import { actions } from "app/appConfigSlice";

export const MailResetPassword = ({ navigation }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [required, setRequired] = useState(false);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setLoading(true);
      const auth = getAuth();
      const data = new FormData(event.currentTarget);
      const email = data.get("email").toString();
      const methods = await fetchSignInMethodsForEmail(auth, email);
      if (methods.includes("password")) {
        sendPasswordResetEmail(auth, email)
          .then((result) => {
            setLoading(false);
            dispatch(
              actions.updateDialog({
                type: DialogType.SNACKBAR_ALERT,
                value: true,
                args: {
                  type: "success",
                  message: I18n.t(
                    "MSG_SIGN_IN_FORGOT_PASSWORD_SUCCESS_CONTENT"
                  ),
                },
              })
            );
            setTimeout(() => navigation.navigate("Sign"), 1000);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            dispatch(
              actions.updateDialog({
                type: DialogType.SNACKBAR_ALERT,
                value: true,
                args: {
                  type: "error",
                  message: I18n.t("MSG_APP_ERROR_SIGN_IN_FAILED"),
                },
              })
            );
          });
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      dispatch(
        actions.updateDialog({
          type: DialogType.SNACKBAR_ALERT,
          value: true,
          args: {
            type: "error",
            message: I18n.t("MSG_APP_ERROR_TITLE"),
          },
        })
      );
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Stack
        mt={8}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ mt: 1, width: "100%" }}
        >
          <div className="flex-col-el">
            <input
              id="email"
              name="email"
              type="email"
              required={required}
              className="peer input-form text-body text-textcolor/black text-font-size-body2-narrow"
              placeholder={I18n.t("MSG_SIGN_USER_MAIL")}
              autoFocus={false}
            />
            <label className="text-body text-left text-[14px] h-5 text-danger hidden peer-invalid:block">
              {I18n.t("MSG_APP_ERROR_EMAIL_NOT_VALID")}
            </label>
          </div>

          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => setRequired(true)}
          >
            {I18n.t("MSG_SIGN_IN_FORGOT_PASSWORD_SEND_BTN")}
          </Button>
        </Box>
      </Stack>
    </Container>
  );
};
