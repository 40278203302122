import I18n from "i18n-js";
import { Stack, Button, Typography } from "@mui/material";
import Constants from "common/constant";
import { useScale } from "common/utils";
import { AppLogo, KidsAppLogo, CompanyLogo } from "./asset";

export const Sign = ({ navigation }) => {
  const TITLE_WIDTH = 810;
  const TITLE_HEIGHT = 120;
  const { scale, width, height } = useScale();

  const handleGotoSign = () => {
    navigation.navigate("SignIn");
  };

  return (
    <Stack
      width="100%"
      height="100%"
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <img
        alt="ログイン背景画面"
        src={`${Constants.assetHost}/assets/images/new_bg_puzzle.png`}
        className="w-full h-full !absolute top-0 left-0 z-[-1] object-cover"
      />

      <Stack
        paddingX={2}
        width="100%"
        direction="column"
        alignItems="center"
        justifyContent="center"
        gap={3}
      >
        <Stack
          my={1}
          flex={1}
          direction="column"
          alignItems="center"
          sx={{
            width: TITLE_WIDTH * scale,
            height: TITLE_HEIGHT * scale,
          }}
        >
          <Stack
            my={1}
            flex={1}
            direction="column"
            alignItems="center"
            sx={{
              width: (TITLE_WIDTH / 2) * scale,
              height: (TITLE_HEIGHT / 2) * scale,
            }}
          >
            <KidsAppLogo />
          </Stack>

          <AppLogo />
        </Stack>

        <Stack
          maxWidth="600px"
          direction="column"
          alignItems="center"
          justifyContent="center"
          gap={3}
        >
          <Typography variant="subtitle1" whiteSpace="pre-wrap">
            {I18n.t("MSG_SIGN_NOTICE")}
          </Typography>

          <Button
            fullWidth
            size="large"
            variant="contained"
            onClick={handleGotoSign}
            sx={{
              height: 42,
              fontSize: 16,
              boxShadow: "none",
              borderRadius: "7px",
            }}
          >
            {I18n.t("MSG_SIGN_IN")}
          </Button>
        </Stack>
      </Stack>

      {width > height ? (
        <Stack mt={1}>
          <Stack width={460 * scale} height={200 * scale}>
            <CompanyLogo />
          </Stack>
        </Stack>
      ) : (
        <Stack position="absolute" bottom={24 * scale}>
          <Stack width={460 * scale} height={200 * scale}>
            <CompanyLogo />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
