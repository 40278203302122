import { Stack, Avatar } from "@mui/material";

export const UserAvatar = (props: { src: string; size?: number }) => {
  const { src, size } = props;
  return (
    <Stack
      width={size ?? 56}
      height={size ?? 56}
      direction="column"
      alignItems="center"
      justifyContent="center"
      bgcolor="#FEFDFE"
      borderRadius={size ?? 56}
      style={{
        transform: "translateZ(0)", // for safari
        filter: "drop-shadow(1px 1px 2px rgba(63, 74, 97, 0.4))",
      }}
    >
      <Avatar src={src} sx={{ width: "85%", height: "85%" }} />
    </Stack>
  );
};
