import { EntityState } from "@reduxjs/toolkit";

export enum MessageType {
  USER_INITIAL = "user_initial",
  NORMAL = "normal",
  CONTACT = "contact",
  CONTACT_INITIAL = "contact_initial",
  CONTACT_REPLY = "contact_reply",
  KIDS_REVIEW_RATING = "kids_review_rating",
  KIDS_REVIEW_RATING_REPLY = "kids_review_rating_reply",
  KIDS_REVIEW_FEEDBACK = "kids_review_feedback",
  KIDS_REVIEW_FEEDBACK_REPLY = "kids_review_feedback_reply",
}

export enum MessageContentType {
  TEXT = "text",
  IMAGE = "image",
  PROJECT = "project",
  COURSE_CLEAR = "course_clear",
}

export type ChatRoom = {
  id: string;
  uid: string;
  name: string;
  icon: string;
  type: string;
  description: string;
  last_read_at: string;
  created_at: string;
  updated_at: string;
  members: ChatRoomMember[];
};

// lastReadAt、latestReadAt、latestMessageCreatedAtはメッセージ未読チェック用

// アプリ開いている時に、lastReadAtとlastReadAtをデータベースから取ったlastReadAtにする
// チャットルームを開いている時に、メッセージ届いた時に、latestReadAtとlatestMessageCreatedAtを同じくメッセージの作成時刻にすること
// チャットルームを開いていない時に、メッセージ届いた時に、latestReadAtを更新しない

// latestMessageCreatedAt > latestReadAt の場合はメッセージ未読あり
// latestReadAtがnullかつlatestMessageCreatedAtがnot nullの場合はメッセージ未読あり
// latestReadAtがnullの場合はチャットルームが始めて作られてまだメッセージを読んだことがない

// lastReadAt !== latestReadAt の場合はチャットルームの最後読んだ時刻とデータベース記録されている最後の読んだ時刻と違い、データベースに更新する必要ある
// lastReadAt === null && latestReadAt !== null の場合はチャットルーム始めて作られかつメッセージを読んだので、データベースに更新する必要ある
export type ChatRoomMessageSync = {
  id: string;
  uid: string;
  lastSyncAt: string; // 一番古いメッセージの同期時刻主にfirestoreからメッセージ同期用
  lastReadAt?: string; // 前回チャットルーム開いている最後メッセージ読んだ時刻、データベースに記録されている
  latestReadAt?: string; // アプリ開いている中に最後メッセージ読んだ時刻
  latestMessage?: ChatRoomMessage; // 最新のメッセージ
  latestMessageCreatedAt?: string; // 最新のメッセージの時刻
};

export enum KidsUserType {
  MAIN = "main",
  SUB = "sub",
}

export enum ChatRoomMemberType {
  KIDS = "kids",
  PARENTS = "parents",
  OFFICIAL = "official",
}
export type ChatRoomMember = {
  id: string;
  chat_room_id: string;
  uid: string;
  type: ChatRoomMemberType;
  last_read_at: string;
  created_at: string;
  updated_at: string;
};

export type ChatRoomMessage = {
  id: string;
  chat_room_id: string;
  user_uid: string;
  user_type: ChatRoomMemberType;
  message_type: MessageType;
  message_content_type: MessageContentType;
  message_content: any;
  created_at: string;
  updated_at: string;
};

export type HomeInitialState = {
  isRefreshed: boolean;
  selectedChatRoomId: string;
  selectedUserUid: string;
  chatRooms: EntityState<ChatRoom>;
  chatRoomMessages: EntityState<ChatRoomMessage>;
  chatRoomMessageSync: EntityState<ChatRoomMessageSync>;
};
