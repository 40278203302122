import React from "react";
import { initializeApp } from "firebase/app";
import { User, getAuth } from "firebase/auth";
import Constants from "common/constant";

const firebaseConfig = {
  apiKey: Constants.apiKey,
  authDomain: Constants.authDomain,
  projectId: Constants.projectId,
  appId: Constants.appId,
  measurementId: Constants.measurementId,
};

initializeApp(firebaseConfig);

export interface AuthUser {
  user?: User;
}
const user: AuthUser = { user: null };
export const AuthContext = React.createContext(user);

export const fetchAuthToken = async () => {
  const auth = getAuth();

  return await auth.currentUser.getIdToken(false);
};
