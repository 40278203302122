import { useState, useContext, ChangeEvent, PointerEvent } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import I18n from "i18n-js";
import MuiAlert from "@mui/material/Alert";
import SendIcon from "@mui/icons-material/Send";
import { Stack, Button, Snackbar, TextField, IconButton } from "@mui/material";
import SentimentSatisfiedOutlinedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";
import { AuthContext } from "app/Auth";
import { RootState, AppDispatch } from "app/store";
import { sendChatRoomMessage } from "features/home/api";
import { ChatRoomMemberType, MessageContentType } from "features/home/types";
import { Stamp } from "./Stamp";

export const InputBar = () => {
  const [text, setText] = useState("");
  const auth = useContext(AuthContext);
  const [showAlert, setShowAlert] = useState(false);
  const [stamp, setStamp] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const selectedChatRoomId = useSelector(
    (state: RootState) => state.home.selectedChatRoomId
  );

  const handleShowStamp = (show: boolean) => () => {
    setStamp(show);
  };

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const handleCloseAlert = () => setShowAlert(false);

  const handleSendMessage = (e: PointerEvent) => {
    if (text === "") return;

    const message = {
      user_uid: auth.user.uid,
      user_type: ChatRoomMemberType.PARENTS,
      message_content_type: MessageContentType.TEXT,
      message_content: { text: text },
      created_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      updated_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    };

    dispatch(
      sendChatRoomMessage({
        authUser: auth.user,
        roomId: selectedChatRoomId,
        message,
      })
    )
      .then(unwrapResult)
      .then(() => {
        setText("");
        setStamp(false);
      })
      .catch((error) => {
        setShowAlert(true);
      });
  };

  const handleSendStamp = (url: string) => () => {
    const message = {
      user_uid: auth.user.uid,
      user_type: ChatRoomMemberType.PARENTS,
      message_content_type: MessageContentType.IMAGE,
      message_content: { url },
      created_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      updated_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    };
    dispatch(
      sendChatRoomMessage({
        authUser: auth.user,
        roomId: selectedChatRoomId,
        message,
      })
    ).then(() => setStamp(false));
  };

  return (
    <Stack
      zIndex={50}
      width="100%"
      direction="column"
      alignItems="center"
      justifyContent="flex-start"
    >
      <Snackbar
        open={showAlert}
        autoHideDuration={5000}
        sx={{ top: { xs: 100, sm: 0 } }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleCloseAlert}
      >
        <MuiAlert
          elevation={6}
          severity="error"
          variant="filled"
          onClose={handleCloseAlert}
        >
          {I18n.t("MSG_CHAT_USER_DELETED_ALERT")}
        </MuiAlert>
      </Snackbar>

      <Stack
        my={1}
        width="100%"
        direction="row"
        alignItems="flex-end"
        justifyContent="center"
      >
        <Stack
          mx={2}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <IconButton
            onClick={handleShowStamp(!stamp)}
            disabled={selectedChatRoomId === null}
          >
            <SentimentSatisfiedOutlinedIcon />
          </IconButton>
        </Stack>

        <Stack flex={1} direction="column">
          <TextField
            fullWidth
            InputProps={{
              sx: { borderRadius: 4, paddingX: 3, paddingY: 1 },
            }}
            variant="outlined"
            multiline
            maxRows={10}
            disabled={selectedChatRoomId === null}
            onClick={(event) => {
              const element = event.target as HTMLElement;
              if (
                element &&
                (element.tagName === "INPUT" || element.tagName === "TEXTAREA")
              ) {
                element.focus();
              }
            }}
            onFocus={handleShowStamp(false)}
            value={text}
            onChange={handleInput}
          />
        </Stack>

        <Button
          className="mx-4"
          disabled={selectedChatRoomId === null}
          variant="contained"
          endIcon={<SendIcon />}
          onPointerDown={handleSendMessage}
        >
          {I18n.t("MSG_CHAT_SEND_BTN")}
        </Button>
      </Stack>

      {stamp && <Stamp handleSendStamp={handleSendStamp} />}
    </Stack>
  );
};
