import { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Backdrop, CircularProgress } from "@mui/material";
import { getNotifications } from "app/api";
import { RootState, AppDispatch } from "app/store";
import { getUserChatRooms } from "features/home/api";
import { Chat } from "./Chat";
import { actions } from "./slice";
import { MenuButton } from "./MenusButton";
import { useSubscribe } from "./useSubscribe";
import { UserSelectMenu } from "./UserSelectMenu";

export const Home = ({ navigation }) => {
  useSubscribe();
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(true);
  const appUser = useSelector((state: RootState) => state.appConfig.appUser);

  useEffect(() => {
    Promise.all([
      dispatch(getNotifications()).unwrap(),
      dispatch(getUserChatRooms({ uid: appUser.main.uid })).unwrap(),
    ]).then(() => {
      setLoading(false);
      if (appUser.active) {
        dispatch(actions.updateSelectedKidsUser({ uid: appUser.active.uid }));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: () => <UserSelectMenu />,
      headerRight: () => <MenuButton navigation={navigation} />,
    });
  }, [navigation]);

  return (
    <Box className="h-full">
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!loading && (
        <Box
          className="w-full h-full flex flex-col"
          sx={{
            bgcolor: "background.paper",
          }}
        >
          <Chat />
        </Box>
      )}
    </Box>
  );
};
