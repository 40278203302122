export enum UserType {
  MAIN = "main",
  SUB = "sub",
  FROZEN = "frozen",
}

export enum AccountType {
  GUEST = "guest",
  KIDS = "kids",
  PARENTS = "parents",
}

export type UserSession = {
  session_id: string;
  created_at: string;
};

export type AppUser = {
  uid: string;
  type: UserType;
  active: boolean;
  account_type: AccountType;
  parent_uid: string;
  email: string;
  provider: string;
  display_name: string;
  birthday: string;
  gender: string;
  icon?: string;
  created_at: string;
  last_sign_in_time: string;
  last_sign_in_env: string;
  login_count: number;
};

export type Notification = {
  id: number;
  ja: { date: string; title: string; content: string };
  published: boolean;
  published_at: string;
};

export enum GameCase {
  GRAY = "gray",
  BLUE = "blue",
  CYAN = "cyan",
  MILK = "milk",
  PINK = "pink",
  GREEN = "green",
  ORANGE = "orange",
  SOFT_PINK = "soft_pink",
  SOFT_YELLOW = "soft_yellow",
}

export enum EventType {
  BEFORE_INSTALL_PROMPT = "before_install_prompt",
}

export enum DialogType {
  SNACKBAR_ALERT = "snackbar_alert",
  PROJECT_PREVIEW = "project_preview",
  PWA_INSTALL_PROMPT = "pwa_install_prompt",
}

export interface AppConfigState {
  config: {
    locale: string;
    onboarding: boolean;
    last_notification_marked_at: string;
  };
  appUser: {
    main: AppUser;
    active: AppUser;
    users: AppUser[];
    session: UserSession;
    idToken: string;
  };
  notifications: Notification[];
  notificationNotMarked: boolean;
  loading: boolean;
  showTabBar: boolean;
  assets: { [prefix: string]: { fileKey: string; fileSize: number }[] };
  event: {
    before_install_prompt: { value: boolean; args?: { [key: string]: string } };
  };
  dialog: {
    snackbar_alert: {
      value: boolean;
      args?: {
        type: "error" | "info" | "success" | "warning";
        message: string;
        [key: string]: string;
      };
    };
    project_preview: { value: boolean; args?: { [key: string]: string } };
    pwa_install_prompt: { value: boolean; args?: { [key: string]: string } };
  };
}
