import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import produce from "immer";
import { AppUser, EventType, DialogType, AppConfigState } from "app/types";
import stamps from "./stamps.json";
import { getUser, getUserConfig, getNotifications } from "./api";

export enum AppPlatform {
  iOS = "ios",
  Android = "android",
  Amazon = "amazon",
  Web = "web",
}

export const getEnv = () => {
  if ("ReactNativeWebView" in window) {
    if ("parentsAppPlatform" in window) {
      return window.parentsAppPlatform;
    } else {
      return "webview";
    }
  } else {
    return "web";
  }
};

const initialState: AppConfigState = {
  config: {
    locale: "ja",
    onboarding: true,
    last_notification_marked_at: null,
  },
  appUser: null,
  notifications: [],
  notificationNotMarked: false,
  loading: true,
  showTabBar: true,
  assets: Object.fromEntries(
    stamps.map((assets) => [assets.prefix, assets.contents])
  ),
  event: { before_install_prompt: { value: false, args: {} } },
  dialog: {
    snackbar_alert: { value: false, args: { type: "success", message: "" } },
    project_preview: { value: false, args: {} },
    pwa_install_prompt: { value: false, args: {} },
  },
};

const appConfigSlice = createSlice({
  name: "appConfig",
  initialState,
  reducers: {
    updateParentsUserProfile(
      state: AppConfigState,
      action: PayloadAction<AppUser>
    ) {
      state.appUser = produce(state.appUser, (draft) => {
        draft.main = action.payload;
        draft.users[0] = action.payload;
      });
    },
    updateLoading(
      state: AppConfigState,
      action: PayloadAction<{
        loading: boolean;
      }>
    ) {
      state.loading = action.payload.loading;
    },
    updateShowTabBar(
      state: AppConfigState,
      action: PayloadAction<{
        show: boolean;
      }>
    ) {
      state.showTabBar = action.payload.show;
    },
    updateNotificationNotMarked(
      state: AppConfigState,
      action: PayloadAction<boolean>
    ) {
      state.notificationNotMarked = action.payload;
    },
    updateDialog(
      state: AppConfigState,
      action: PayloadAction<{
        type: DialogType;
        value: boolean;
        args?: { [key: string]: string };
      }>
    ) {
      state.dialog[action.payload.type].value = action.payload.value;
      state.dialog[action.payload.type].args = action.payload.args;
    },
    updateEvent(
      state: AppConfigState,
      action: PayloadAction<{
        type: EventType;
        value: boolean;
        args?: { [key: string]: string };
      }>
    ) {
      state.event[action.payload.type].value = action.payload.value;
      state.event[action.payload.type].args = action.payload.args;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getUser.fulfilled,
        (state: AppConfigState, action: PayloadAction<any>) => {
          state.appUser = action.payload;
        }
      )
      .addCase(
        getUserConfig.fulfilled,
        (state: AppConfigState, action: PayloadAction<any>) => {
          state.config = action.payload.user_config;
        }
      )
      .addCase(
        getNotifications.fulfilled,
        (state: AppConfigState, action: PayloadAction<any>) => {
          state.notifications = action.payload.parents;
          if (state.notifications.length > 0) {
            if (state.config.last_notification_marked_at === null) {
              state.notificationNotMarked = true;
            } else {
              const published_at = dayjs(state.notifications[0].published_at);
              const last_notification_marked_at = dayjs(
                state.config.last_notification_marked_at
              );
              if (published_at > last_notification_marked_at) {
                state.notificationNotMarked = true;
              }
            }
          }
        }
      );
  },
});

export const actions = { ...appConfigSlice.actions };

export default appConfigSlice.reducer;
