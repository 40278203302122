import { useState } from "react";
import { useSelector } from "react-redux";
import { Stack } from "@mui/material";
import MobileStepper from "@mui/material/MobileStepper";
import { RootState } from "app/store";
import { stampCategories } from "app/assets";
import Constants from "common/constant";

export const Stamp = (props: {
  handleSendStamp: (url: string) => () => void;
}) => {
  const { handleSendStamp } = props;
  const [activeStep, setActiveStep] = useState(0);
  const assets = useSelector((state: RootState) => state.appConfig.assets);
  const [selectedCategory, setSelectedCategory] = useState("stamps/character");

  const handleSelectCategory = (category: string, index: number) => () => {
    setActiveStep(index);
    setSelectedCategory(category);
  };

  return (
    <Stack
      width="100%"
      direction="column"
      alignItems="center"
      justifyContent="flex-end"
      className="bg-white"
    >
      <Stack
        width="100%"
        direction="row"
        alignItems="center"
        justifyContent="center"
        overflow="hidden"
        className="bg-white"
      >
        <Stack
          flex={1}
          width="100%"
          height="100%"
          direction="row"
          className="overflow-x-auto bg-white"
          sx={{ "&::-webkit-scrollbar": { display: "none" } }}
        >
          {stampCategories.map((category, index) => (
            <Stack
              key={index}
              flexShrink={0}
              padding="10px"
              width="50px"
              height="50px"
              direction="row"
              alignItems="center"
              justifyContent="center"
              className={activeStep === index && "bg-gray2/10 rounded-[8px]"}
              onClick={handleSelectCategory(category, index)}
            >
              <img
                alt="スタンプ画像"
                src={`${Constants.assetHost}/${assets[category][0].fileKey}`}
                className="w-full h-full"
              />
            </Stack>
          ))}
        </Stack>
      </Stack>

      <Stack
        width="100%"
        height="300px"
        direction="column"
        alignItems="center"
        justifyContent="center"
        overflow="auto"
      >
        <div className="grid grid-flow-row grid-cols-stamp-auto-fill justify-center w-full h-full">
          {assets[selectedCategory].map((asset, index) => (
            <Stack
              key={index}
              flexShrink={0}
              width="70px"
              height="70px"
              direction="column"
              alignItems="center"
              justifyContent="center"
              margin="10px"
            >
              <img
                alt="スタンプ画像"
                src={`${Constants.assetHost}/${asset.fileKey}`}
                className="w-full h-full"
                onClick={handleSendStamp(
                  `${Constants.assetHost}/${asset.fileKey}`
                )}
              />
            </Stack>
          ))}
        </div>
      </Stack>

      <MobileStepper
        variant="dots"
        steps={stampCategories.length}
        position="static"
        className="bg-white"
        activeStep={activeStep}
        nextButton={<></>}
        backButton={<></>}
      />
    </Stack>
  );
};
