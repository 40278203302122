import I18n from "i18n-js";
import { Typography } from "@mui/material";
import { MessageType, ChatRoomMessage } from "features/home/types";

export const OfficialChatMessageItem = (props: {
  message: ChatRoomMessage;
}) => {
  const { message } = props;
  switch (message.message_type) {
    case MessageType.USER_INITIAL:
      if (message.message_content.users) {
        return (
          <div className="w-full flex flex-row items-center justify-center mt-4">
            <Typography
              sx={{ display: "inline" }}
              component="span"
              variant="body2"
              className="text-gray/100 bg-gray/20 px-3 py-1 rounded-[12px]"
            >
              {`${message.message_content.users?.map((user) => user)} ${I18n.t(
                "MSG_CHAT_MESSAGE_PARENTS_TALK_WELCOME_TEXT"
              )}`}
            </Typography>
          </div>
        );
      } else {
        return <></>;
      }
  }
};
