import { useRef, useState, FormEventHandler, ChangeEventHandler } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Stack,
  Badge,
  Avatar,
  Button,
  Backdrop,
  TextField,
  IconButton,
  CircularProgress,
} from "@mui/material";
import I18n from "i18n-js";
import { v4 as uuidv4 } from "uuid";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { DialogType } from "app/types";
import { actions } from "app/appConfigSlice";
import { RootState, AppDispatch } from "app/store";
import Constants from "common/constant";
import { UserAvatar } from "common/elements";
import { updateUserProfile, uploadImageFile } from "features/profile/api";

export const Profile = () => {
  const ref = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const [canSave, setCavSave] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state: RootState) => state.appConfig.appUser.main);
  const [icon, setIcon] = useState(user.icon);

  const pickImage: ChangeEventHandler<HTMLInputElement> = async (e) => {
    setCavSave(true);
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = ({ target }) => {
        setIcon(target.result as string);
      };
    }
  };

  const handleSave: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    setLoading(true);

    const data = new FormData(e.currentTarget);
    const name = data.get("name").toString();
    const uuid = uuidv4();
    const file = ref.current.files[0];
    if (file) {
      const filename = `parents/${user.uid}/assets/icons/${uuid}.png`;
      const url = `${Constants.assetHost}/${filename}`;
      uploadImageFile({
        filename,
        fileBlob: file,
      }).then(() => {
        updateUserProfile({
          uid: user.uid,
          userInfo: { name, icon: url },
        })
          .then(() => {
            setCavSave(false);
            ref.current.value = "";
            dispatch(
              actions.updateParentsUserProfile({
                ...user,
                display_name: name,
                icon: url,
              })
            );
            dispatch(
              actions.updateDialog({
                type: DialogType.SNACKBAR_ALERT,
                value: true,
                args: { type: "success", message: "変更しました。" },
              })
            );
          })
          .catch((error) => {
            console.error(error);
            dispatch(
              actions.updateDialog({
                type: DialogType.SNACKBAR_ALERT,
                value: true,
                args: { type: "error", message: "失敗しました。" },
              })
            );
          })
          .finally(() => setLoading(false));
      });
    } else {
      updateUserProfile({
        uid: user.uid,
        userInfo: {
          name,
        },
      })
        .then(() => {
          setCavSave(false);
          dispatch(
            actions.updateParentsUserProfile({
              ...user,
              display_name: name,
            })
          );
          dispatch(
            actions.updateDialog({
              type: DialogType.SNACKBAR_ALERT,
              value: true,
              args: { type: "success", message: "変更しました。" },
            })
          );
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Stack
      px={8}
      width="100%"
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Stack
        mt={8}
        width="100%"
        maxWidth="sm"
        component="form"
        onSubmit={handleSave}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={
            <Avatar sx={{ width: 24, height: 24 }}>
              <CameraAltIcon sx={{ width: 16, height: 16 }} />
            </Avatar>
          }
        >
          <IconButton component="label" sx={{ padding: 0 }}>
            <input
              hidden
              ref={ref}
              type="file"
              accept="image/*"
              onChange={pickImage}
            />
            <UserAvatar src={icon} size={140} />
          </IconButton>
        </Badge>
        <TextField
          fullWidth
          id="name"
          name="name"
          margin="normal"
          variant="standard"
          onClick={(event) => {
            const element = event.target as HTMLElement;
            if (
              element &&
              (element.tagName === "INPUT" || element.tagName === "TEXTAREA")
            ) {
              element.focus();
            }
          }}
          onChange={() => setCavSave(true)}
          defaultValue={user.display_name}
          label={I18n.t("MSG_PROFILE_NAME")}
          placeholder={I18n.t("MSG_PROFILE_NAME")}
        />
        <Button
          fullWidth
          type="submit"
          variant="contained"
          sx={{ mt: 3 }}
          disabled={!canSave}
        >
          {I18n.t("MSG_PROFILE_SAVE_BTN")}
        </Button>
      </Stack>
    </Stack>
  );
};
