import I18n from "i18n-js";
import { Stack } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

export const UserUnsubscribe = () => (
  <Stack
    width="100%"
    direction="column"
    alignItems="center"
    justifyContent="center"
  >
    <Stack
      px={4}
      mt={4}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Stack
        width="100%"
        direction="row"
        alignItems="center"
        justifyContent="center"
        gap={1}
      >
        <WarningAmberIcon sx={{ fontSize: 40, color: "#FFA03B" }} />
        <p className="text-body text-font-size-body2 whitespace-pre-wrap">
          {I18n.t("MSG_APP_ABOUT_DELETE_PARENTS_USER_TITLE")}
        </p>
      </Stack>

      <p className="whitespace-pre-wrap">
        {I18n.t("MSG_APP_ABOUT_DELETE_PARENTS_USER_CONTENTS")}
      </p>
    </Stack>
  </Stack>
);
