import { useContext, useEffect } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import PullToRefresh from "react-simple-pull-to-refresh";
import { Stack, CircularProgress } from "@mui/material";
import { AuthContext } from "app/Auth";
import { RootState, AppDispatch } from "app/store";
import { getChatRoomMessages, markChatRoomMessage } from "features/home/api";
import { actions, selectChatRoomMessageSyncById } from "features/home/slice";
import { InputBar } from "./InputBar";
import { ChatMessageList } from "./ChatMessageList";

export const Chat = () => {
  const auth = useContext(AuthContext);
  const dispatch = useDispatch<AppDispatch>();
  const selectedChatRoomId = useSelector(
    (state: RootState) => state.home.selectedChatRoomId
  );
  const lastMessageSyncAt = useSelector((state: RootState) =>
    selectChatRoomMessageSyncById(state, selectedChatRoomId)
  );

  const handleRefresh = () =>
    new Promise((resovle) => {
      dispatch(actions.updateIsRefreshed({ refreshed: true }));
      dispatch(
        getChatRoomMessages({
          roomId: selectedChatRoomId,
          lastMessageSyncAt: lastMessageSyncAt.lastSyncAt,
          messageCount: 5,
        })
      )
        .then(unwrapResult)
        .then(() => {
          resovle(0);
        });
    });

  useEffect(() => {
    return () => {
      dispatch(markChatRoomMessage({ authUser: auth.user }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChatRoomId]);

  return (
    <>
      <PullToRefresh
        className="w-full"
        onRefresh={handleRefresh}
        pullingContent={""}
        refreshingContent={
          <Stack
            height="80px"
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Stack>
        }
      >
        <ChatMessageList />
      </PullToRefresh>
      <InputBar />
    </>
  );
};
