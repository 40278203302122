import * as React from "react";
import { SVGProps } from "react";

export const BandageIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M46 2v44H25.99C12.588 46 2 35.999 2 24S12.589 2 25.99 2H46Z"
      fill="#F2EFF1"
      stroke="#FEFDFE"
      strokeWidth={4}
    />
    <path fill="#F2EFF1" d="M36.065 39H19.157V9h16.908z" />
    <path d="M33.065 39h3V9h-3v30Z" fill="#DBD8DA" />
    <path
      opacity={0.1}
      d="M36.065 9v30h-3v-1.25h2.247V9h.753Z"
      fill="#3F4A61"
    />
    <path d="M26.065 39h3V9h-3v30Z" fill="#DBD8DA" />
    <path
      opacity={0.1}
      d="M29.065 9v30h-3v-1.25h2.247V9h.753Z"
      fill="#3F4A61"
    />
    <path d="M19.065 39h3V9h-3v30Z" fill="#DBD8DA" />
    <path
      opacity={0.1}
      d="M22.065 9v30h-3v-1.25h2.247V9h.753Z"
      fill="#3F4A61"
    />
  </svg>
);
