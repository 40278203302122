import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  query,
  where,
  limit,
  orderBy,
  getDocs,
  collection,
  getFirestore,
} from "firebase/firestore";
import { User } from "firebase/auth";
import { RootState } from "app/store";
import { fetchAuthToken } from "app/Auth";
import { client } from "common/apiClient";

export const getUserChatRooms = createAsyncThunk(
  "home/getUserChatRooms",
  async ({ uid }: { uid: string }) => {
    const token = await fetchAuthToken();
    const response = await client({ token: token }).get(`/v1/chat/room/${uid}`);
    return response.data.data;
  }
);

export const sendChatRoomMessage = createAsyncThunk(
  "home/sendChatRoomMessage",
  async ({
    authUser,
    roomId,
    message,
  }: {
    authUser: User;
    roomId: string;
    message: {};
  }) => {
    const token = await authUser.getIdToken(false);
    await client({ token: token }).post(
      `/v1/parents/chat/room/message/${roomId}`,
      message
    );
    return;
  }
);

export const getChatRoomMessages = createAsyncThunk(
  "home/getChatRoomMessages",
  async ({
    roomId,
    lastMessageSyncAt,
    messageCount,
  }: {
    roomId: string;
    lastMessageSyncAt: string;
    messageCount: number;
  }) => {
    const db = getFirestore();
    const queryMessage = query(
      collection(db, "rooms", roomId, "messages"),
      where("created_at", "<", lastMessageSyncAt),
      orderBy("created_at", "desc"),
      limit(messageCount)
    );
    const querySnapshot = await getDocs(queryMessage);
    const messages = querySnapshot.docs.map((doc) => doc.data());
    return { messages };
  }
);

export const markChatRoomMessage = createAsyncThunk(
  "home/markChatRoomMessage",
  async (
    {
      authUser,
    }: {
      authUser: User;
    },
    thunkAPI
  ) => {
    const { home } = thunkAPI.getState() as RootState;
    const token = await authUser.getIdToken(false);

    const chatRoomsReadAt = home.chatRoomMessageSync.ids
      .map((id) => home.chatRoomMessageSync.entities[id])
      .filter(
        (sync) =>
          sync.lastReadAt !== sync.latestReadAt ||
          (sync.lastReadAt === null && sync.latestReadAt !== null)
      )
      .map((sync) => ({
        chat_room_id: sync.id,
        uid: authUser.uid,
        last_read_at: sync.latestReadAt,
      }));

    const response = await client({ token: token }).put(
      `/v1/parents/chat/room/marked`,
      {
        chatRoomsReadAt,
      }
    );
    return response.data.data;
  }
);
