import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  List,
  Divider,
  ListItem,
  IconButton,
  Typography,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import I18n from "i18n-js";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { actions } from "app/appConfigSlice";
import { AppDispatch, RootState } from "app/store";
import { markParentsNotification } from "features/notification/api";

export const Notification = ({ navigation }) => {
  const dispatch = useDispatch<AppDispatch>();
  const notifications = useSelector(
    (state: RootState) => state.appConfig.notifications
  );
  const notificationNotMarked = useSelector(
    (state: RootState) => state.appConfig.notificationNotMarked
  );
  const user = useSelector((state: RootState) => state.appConfig.appUser.main);

  useEffect(() => {
    if (notificationNotMarked) {
      markParentsNotification({ uid: user.uid }).then(() =>
        dispatch(actions.updateNotificationNotMarked(false))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenNotification = (notification: {
    date: string;
    title: string;
    content: string;
  }) => () => {
    navigation.navigate("NotificationDetail", { notification });
  };

  return (
    <List
      sx={{ width: "100%", bgcolor: "background.paper", overflow: "auto" }}
      disablePadding
    >
      {notifications.map((notification, index) => (
        <div key={index}>
          <ListItem
            alignItems="flex-start"
            secondaryAction={
              <IconButton edge="end" aria-label="comments">
                <KeyboardArrowRightIcon />
              </IconButton>
            }
            disablePadding
          >
            <ListItemButton
              onClick={handleOpenNotification(notification[I18n.locale])}
            >
              <ListItemText
                primary={notification[I18n.locale].title}
                secondary={
                  <>
                    <span className="overflow-hidden m-0 block">
                      <Typography
                        sx={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 3,
                        }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {notification[I18n.locale].content}
                      </Typography>
                    </span>

                    {notification[I18n.locale].date}
                  </>
                }
              />
            </ListItemButton>
          </ListItem>
          <Divider />
        </div>
      ))}
    </List>
  );
};
