import { useWindowDimensions } from "react-native";
import { Typography } from "@mui/material";
import { ChatRoomMessage, MessageContentType } from "features/home/types";
import { ProjectItemComponent } from "./ProjectItemComponent";

export const MessageItem = ({
  message,
  isFromUser,
}: {
  message: ChatRoomMessage;
  isFromUser: boolean;
}) => {
  const { width, height } = useWindowDimensions();

  switch (message.message_content_type) {
    case MessageContentType.TEXT:
      return (
        <div
          className={`max-w-[60%] p-3 rounded-md ${
            isFromUser ? "bg-safe ml-1" : "bg-gray/20 mr-1"
          }`}
        >
          <Typography
            className="break-words"
            component="p"
            variant="body1"
            color="text.primary"
          >
            {message.message_content.text}
          </Typography>
        </div>
      );

    case MessageContentType.IMAGE:
      return (
        <div className={`max-w-[60%] ${isFromUser ? "ml-1" : "mr-1"}`}>
          <img
            alt="画像メッセージ"
            src={message.message_content.url}
            className="w-[100px] h-[100px]"
          />
        </div>
      );

    case MessageContentType.COURSE_CLEAR:
      return width > height ? (
        <div
          className={`flex flex-row items-center justify-center p-3 rounded-md gap-3 max-w-[70%] ${
            isFromUser ? "bg-safe ml-1" : "bg-gray/20 mr-1"
          }`}
        >
          <img
            alt="画像メッセージ"
            className="w-[97px] h-[120px] shrink-0"
            src={message.message_content.image}
          />
          <div className="flex flex-col items-center justify-center">
            <Typography
              component="p"
              variant="body1"
              color="text.primary"
              whiteSpace="pre-line"
            >
              {message.message_content.text}
            </Typography>
          </div>
        </div>
      ) : (
        <div
          className={`flex flex-col items-center justify-center gap-1 max-w-[70%] ${
            isFromUser ? "ml-1" : "mr-1"
          }`}
        >
          <img
            alt="画像メッセージ"
            className="w-[97px] h-[120px] shrink-0"
            src={message.message_content.image}
          />
          <div
            className={`flex flex-col items-center justify-center p-3 rounded-md ${
              isFromUser ? "bg-safe" : "bg-gray/20"
            }`}
          >
            <Typography
              component="p"
              variant="body1"
              color="text.primary"
              whiteSpace="pre-line"
            >
              {message.message_content.text}
            </Typography>
          </div>
        </div>
      );

    case MessageContentType.PROJECT:
      return <ProjectItemComponent messageContent={message.message_content} />;

    default:
      return <></>;
  }
};
