import * as Sentry from "@sentry/react";
import { isLocalhost } from "common/utils";

type SwConfig = {
  isUpdate: boolean;
};

export const register = (config: SwConfig) => {
  console.log("service worker register.");
  const isEnvProduction = process.env.NODE_ENV === "production";
  if (isEnvProduction && "serviceWorker" in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      return;
    }

    const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
    if (isLocalhost) {
      checkValidServiceWorker(swUrl, config);
      navigator.serviceWorker.ready.then(() => {
        console.log(
          "This web app is being served cache-first by a service " +
            "worker. To learn more, visit https://cra.link/PWA"
        );
      });
    } else {
      registerValidSW(swUrl, config);
    }
  }
};

export const registerValidSW = (swUrl: string, config: SwConfig) => {
  navigator.serviceWorker
    .register(swUrl)
    .then(async (registration) => {
      console.log("service worker registration. ");
      if (config && config.isUpdate) {
        await registration.update();

        setTimeout(() => {
          Sentry.captureException("service worker update timeout.");
          window.location.reload();
        }, 10000);

        registration.onupdatefound = () => {
          console.log("service worker update.");
          const installingWorker = registration.installing;
          if (installingWorker == null) {
            return;
          }
          installingWorker.onstatechange = () => {
            if (installingWorker.state === "installed") {
              registration.waiting.postMessage({ type: "SKIP_WAITING" });
              window.location.reload();
            }
          };
        };
      }
    })
    .catch((error) => {
      console.error(
        "Error during service worker registration:",
        JSON.stringify(error)
      );
      Sentry.captureException("Error during service worker registration:");
    });
};

export const checkValidServiceWorker = (swUrl: string, config: SwConfig) => {
  fetch(swUrl, {
    headers: { "Service-Worker": "script" },
  })
    .then((response) => {
      const contentType = response.headers.get("content-type");
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf("javascript") === -1)
      ) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    });
};

export const unregister = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
};
