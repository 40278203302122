import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Stack, Backdrop, CircularProgress } from "@mui/material";
import { AppDispatch } from "app/store";
import { actions } from "app/appConfigSlice";
import * as serviceWorkerRegistration from "app/serviceWorkerRegistration";
import Constants from "common/constant";
import { getParentsLatestVersion } from "features/splash/api";

export const Splash = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const currentVersion = `${Constants.major}.${Constants.minor}.${Constants.buildNumber}`;
    dispatch(getParentsLatestVersion({ key: Constants.apiAuthKey }))
      .then(unwrapResult)
      .then(async (result) => {
        if (result.version !== currentVersion && !window.location.hash) {
          window.location.href =
            window.location.href + `#loaded?version=${result.version}`;
          if (
            !window.ReactNativeWebView &&
            process.env.NODE_ENV === "production"
          ) {
            serviceWorkerRegistration.register({
              isUpdate: true,
            });
          } else {
            window.location.reload();
          }
        } else {
          if (
            !window.ReactNativeWebView &&
            process.env.NODE_ENV === "production"
          ) {
            serviceWorkerRegistration.register({
              isUpdate: false,
            });
          }
          dispatch(actions.updateLoading({ loading: false }));
        }
      })
      .catch((error) => {
        if (
          !window.ReactNativeWebView &&
          process.env.NODE_ENV === "production"
        ) {
          serviceWorkerRegistration.register({
            isUpdate: false,
          });
        }
        console.log(JSON.stringify(error));
        dispatch(actions.updateLoading({ loading: false }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack
      height="100%"
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Stack>
  );
};
