import { useState } from "react";
import { useDispatch } from "react-redux";
import I18n from "i18n-js";
import { Stack, Button, Typography } from "@mui/material";
import { DialogType } from "app/types";
import { AppDispatch } from "app/store";
import { actions } from "app/appConfigSlice";
import { useScale } from "common/utils";
import { BandageIcon, CloseBtnIcon } from "common/icons";

export const Menu = () => {
  const { scale } = useScale();
  const dispatch = useDispatch<AppDispatch>();
  const [collapse, setCollapse] = useState(false);

  const handleClose = () => {
    dispatch(
      actions.updateDialog({ type: DialogType.PROJECT_PREVIEW, value: false })
    );
  };

  const handleCollapse = () => {
    setCollapse(!collapse);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      position="absolute"
      padding={0}
      top="77%"
      zIndex="10000"
      right={-152 * scale}
      sx={{
        transformOrigin: "top right",
        transform: `scale(${scale})`,
      }}
    >
      <Stack
        direction="row"
        height={126}
        padding={2}
        bgcolor="#FEFDFE"
        sx={{
          borderStartStartRadius: 16,
          borderEndStartRadius: 16,
          filter: "drop-shadow(3px 3px 3px rgba(63, 74, 97, 0.4))",
          transition: "transform 0.3s ease-out",
          transform: `translate(${collapse ? 0 : -152}px, 0)`,
        }}
      >
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          width={48}
          height={48}
          position="absolute"
          top={39}
          left={-48}
          sx={{ cursor: "pointer" }}
          onClick={handleCollapse}
        >
          <BandageIcon />
        </Stack>

        <Button
          sx={{
            width: 120,
            height: "100%",
            bgcolor: "#FC6560",
            borderRadius: "10px",
            padding: 0,
          }}
          onClick={handleClose}
        >
          <Stack direction="column" alignItems="center" justifyContent="center">
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              width={32}
              height={32}
            >
              <CloseBtnIcon />
            </Stack>

            <Typography
              variant="body1"
              color="#FEFDFE"
              align="center"
              sx={{ whiteSpace: "pre-line" }}
            >
              {I18n.t("MSG_PROJECT_PREVIEW_MENU_CLOSE_BTN")}
            </Typography>
          </Stack>
        </Button>
      </Stack>
    </Stack>
  );
};
